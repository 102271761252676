import React, { useEffect, useState, useContext } from "react";
import ReactLoading from "react-loading";
import {
  cilTrash,
  cibStatuspage,
  cilLowVision,
  cilPencil,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  API_HEADER,
  SavePageData,
  GetPageData,
} from "../../../../../Components/utiles/api_constant";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Chip } from "@mui/material";
import { toast } from "react-toastify";
import { CCard, CCardHeader, CCol, CForm, CFormLabel } from "@coreui/react";

const UpdateInfo = ({ showSidebar }) => {
  const [eventList, setEventList] = useState([]);
  const [formShow, setFormShow] = useState(false);
  const api_url = GetPageData;
  const api_url3 = SavePageData;
  const api_header = API_HEADER;
  const [refreshEventList, setRefereshList] = useState(false);
  const [selectRow, setSelectRow] = useState("");
  const [text, setText] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const handleFormShow = () => {
    setFormShow(true);
  };
  useEffect(() => {
    setText(selectRow?.pageDataHindi);
    setDescription(selectRow?.pageData);
  }, [selectRow]);
  const columns = [
    {
      headerName: "Sr.",
      field: "pageUniqueSerial",
      width: 20,
      renderHeader: () => (
        <div style={{ fontWeight: "bold" }}>
          <span>Sr.</span>
        </div>
      ),
    },
    {
      headerName: "English Text ",
      field: "pageData",
      width: 530,

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}
        >
          <span style={{ marginLeft: 8 }}>English Text</span>
        </div>
      ),
    },

    {
      headerName: "Hindi Text",
      field: "pageDataHindi",
      width: 530,

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}
        >
          <span style={{ marginLeft: 8 }}>Hindi Text</span>
        </div>
      ),
    },
    {
      field: "eventStatus",
      headerName: "Hide/Show",
      width: 90,

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}
        >
          <span style={{ marginLeft: 8 }}>Hide/Show</span>
        </div>
      ),
      renderCell: (params) =>
        params.row.status === "A" ? (
          <>
            <Chip label="Hide" color="success" size="small" />
          </>
        ) : (
          <Chip label="Show" size="small" />
        ),
    },
    {
      headerName: "Action",
      field: "Action",
      type: "actions",
      width: 80,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}
        >
          <span style={{ marginLeft: 8 }}>Action</span>
        </div>
      ),
      getActions: (params) => [
        <GridActionsCellItem
          icon={<CIcon icon={cilPencil} style={{ width: "20px" }} />}
          label="Edit"
          onClick={() => {
            let row = params.row;
            editEvent(row?.pageUniqueSerial);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<CIcon icon={cibStatuspage} style={{ width: "20px" }} />}
          label=" Show"
          onClick={() => handleEventStatusChange(params.row, "A")}
          showInMenu
        />,

        <GridActionsCellItem
          icon={<CIcon icon={cilLowVision} style={{ width: "20px" }} />}
          label=" Hide"
          onClick={() => handleEventStatusChange(params.row, "P")}
          showInMenu
        />,

        <GridActionsCellItem
          label="Delete "
          icon={<CIcon icon={cilTrash} style={{ width: "20px" }} />}
          onClick={() => handleDeleteEvent(params.row)}
          showInMenu
        />,
      ],
    },
  ];
  const handleFormHide = () => {
    setFormShow(false);
    setText("");
    setDescription("");
  };
  // image delete
  const handleDeleteEvent = (eventData) => {
    var api_param = {
      brandCode: "SKDMT",
      countryCode: "IN",
      companyId: "SKDMT",
      userId: "VINOD",
      ipAddress: "1::1",
      pageUniqueSerial: eventData?.pageUniqueSerial,
      displayOrder: 1,
      status: "A",
      action: "D",
      pageCode: "UPDATES", //MISC
      pageData: eventData?.pageData,
      PageDataHindi: eventData?.pageDataHindi,
    };
    fetch(api_url3, {
      method: "POST",
      body: JSON.stringify(api_param),
      headers: api_header,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        toast.success("Deleted SuccessFully !");
        setRefereshList(!refreshEventList);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const handleEventStatusChange = async (eventData, eventStatus) => {
    try {
      const api_param = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        pageUniqueSerial: eventData?.pageUniqueSerial,
        displayOrder: 1,
        status: eventStatus,
        pageCode: "UPDATES", //MISC
        pageData: eventData?.pageData,
        PageDataHindi: eventData?.pageDataHindi,
        action: "M",
      };
      const response = await fetch(api_url3, {
        method: "POST",
        body: JSON.stringify(api_param),
        headers: api_header,
      });
      if (!response.ok) throw new Error("Network response was not ok");
      await response.json();
      toast.success("Status Updated !");
      setRefereshList(!refreshEventList);
    } catch (error) {
      toast.error(error.message);
    }
  };
  // edit events function
  const editEvent = (pageUniqueSerial) => {
    const rowSelection = eventList?.find(
      (item) => item.pageUniqueSerial === pageUniqueSerial
    );

    setSelectRow(rowSelection);
    setFormShow(true);
  };
  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileChange = (e) => {
    setSelectedFiles(Array.from(e.target.files));
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = api_url;
      const headers = api_header;
      const data = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        pageUniqueSerial: 0,
        pageCode: "UPDATES",
        calledBy: "ALL",
      };
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const responseData = await response.json();
          const respo = responseData?.data;
          setEventList(respo?.pageDataList);
        } else {
          throw new Error(
            `Request failed with status code: ${response.status}`
          );
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [refreshEventList]);
  const handleUpdateSubmit = (actionType) => {
    if (!description) {
      toast.error("Please fill both the Text Fields");
      return;
    }

    if (!text) {
      toast.error("Please fill both the Text Fields");
      return;
    }

    var api_param = {
      brandCode: "SKDMT",
      countryCode: "IN",
      companyId: "SKDMT",
      userId: "VINOD",
      ipAddress: "1::1",
      pageUniqueSerial: selectRow === null ? 0 : selectRow?.pageUniqueSerial,
      displayOrder: 1,
      status: "A",
      action: actionType,
      pageCode: "UPDATES", //MISC
      pageData: description,
      PageDataHindi: text,
    };

    fetch(api_url3, {
      method: "POST",
      body: JSON.stringify(api_param),
      headers: api_header,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        setText("");
        setDescription("");
        toast.success("SuccessFull !!");
        handleFormHide();
        setRefereshList(!refreshEventList);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div onClick={showSidebar}>
      <CCol xs={12}>
        <CCard className="mb-4" style={{ margin: "20px" }}>
          <CCardHeader>
            <div className="btn_tx">
              <>
                {" "}
                <p>Update List</p>
                {formShow === true ? (
                  ""
                ) : (
                  <button
                    className="btn btn-danger add_event_btn"
                    onClick={handleFormShow}
                  >
                    Add New
                  </button>
                )}
              </>
            </div>
          </CCardHeader>
          {/* event creation form  */}
          {formShow === true ? (
            <div>
              <CForm>
                <div className="row">
                  <div className="col-md-6">
                    <div className="p-3">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        {" "}
                        English Text
                      </CFormLabel>
                      <textarea
                        className="form-control  fom_ht"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        type="text"
                        id="exampleFormControlInput1"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="p-3">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        {" "}
                        Hindi Text
                      </CFormLabel>
                      <textarea
                        className="form-control fom_ht"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        type="text"
                        id="exampleFormControlInput1"
                      />
                    </div>
                  </div>
                </div>

                <div class="btn_form_mn">
                  <button
                    className="sv_btn"
                    onClick={() => {
                      selectRow.length === 0
                        ? handleUpdateSubmit("A")
                        : handleUpdateSubmit("M");
                    }}
                  >
                    Save
                  </button>

                  <button
                    className="cn_btn"
                    onClick={() => {
                      handleFormHide();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </CForm>
            </div>
          ) : (
            <>
              <div style={{ height: 500, width: "100%" }}>
                {isLoading && (
                  <div className="loading-container">
                    <ReactLoading
                      type="spin"
                      color="#9f0101 "
                      height={100}
                      width={100}
                    />
                  </div>
                )}

                <DataGrid
                  rows={eventList}
                  getRowId={(r) => r.pageUniqueSerial}
                  columns={columns}
                  rowSelection={false}
                  autoPagination={false}
                  keepNonExistentRowsSelected={false}
                  pageSize={true}
                  pageSizeOptions={false}
                />
              </div>
            </>
          )}
        </CCard>
      </CCol>
    </div>
  );
};
export default UpdateInfo;
