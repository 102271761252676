import React, { useState, useEffect } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import "./responsive .css";
import HomePage from "./Components/home/HomePage";
import Contact from "./Components/contact/Contact";
import About from "./Components/about/About";
import Gallery from "./Components/gallery/Gallery";
import EventForm from "./Components/events/EventForm";
import Information from "./Components/siddhiDetails/Information";
import InformationDetails from "./Components/mDetails/InformationDetails";
import DonationForm from "./Components/other/DonationForm";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Components/home/Footer";
import ScrollTop from "./ScrollTop";
import Maharaj from "./Components/gallery/Maharaj";
import Sidhimaa from "./Components/gallery/Sidhimaa";
import KaichiDham from "./Components/gallery/KaichiDham";
import Login from "./Pages/login/Login";
import EventsTable from "./Pages/login/components/dashoard/Events/EventsTable";
import EventsForm from "./Pages/login/components/dashoard/Events/EventsForm";
import Navbar from "./Components/home/Navbar";
import Ticker from "./Pages/login/components/dashoard/Events/Ticker";
import UpdateInfo from "./Pages/login/components/dashoard/Events/UpdateInfo";
import GalleryAdmin from "./Pages/login/components/dashoard/Events/GalleryAdmin";
import Comment from "./Pages/login/components/dashoard/Events/Comment";
import { API_HEADER, SaveVisitorData } from "./Components/utiles/api_constant";
import EnquiryForm from "./EnquiryForm";
import Faq from "./Pages/login/components/dashoard/Events/Faq";
import Demo from "./Components/Demo";
function AppContent() {
  const [pageLogin, setPageLogin] = useState(false);
  const location = useLocation();
  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => {
    setSidebar(!sidebar);
  };
  const closeSidebar = () => {
    setSidebar(false);
  };
  const isAdminRoute =
    location.pathname.startsWith("/admin_dashboard") ||
    location.pathname.startsWith("/demo") ||
    location.pathname.startsWith("/event_formDetails") ||
    location.pathname.startsWith("/ticker_header") ||
    location.pathname.startsWith("/update_info") ||
    location.pathname.startsWith("/gallery_image") ||
    location.pathname.startsWith("/comment_list") ||
    location.pathname.startsWith("/faqs_section");
  const isLoginRoute = location.pathname === "/login";
  const generateSessionId = () => {
    return "xxxx-xxxx-4xxx-yxxx-xxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const handleVisitor = () => {
    const api_param = {
      brandCode: "SKDMT",
      countryCode: "IN",
      companyId: "SKDMT",
      ipAddress: "1::1",
      sessionId: generateSessionId(),
    };
    fetch(SaveVisitorData, {
      method: "POST",
      body: JSON.stringify(api_param),
      headers: API_HEADER,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        sessionStorage.setItem("visitorData", JSON.stringify(response));
        const sessionId = generateSessionId();
        sessionStorage.setItem("token", response.token);
        sessionStorage.setItem("sessionId", sessionId);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const token = sessionStorage.getItem("session");
    handleVisitor();
  }, []);

  // useEffect(() => {
  //   const handleCopy = (event) => {
  //     event.preventDefault();
  //   };

  //   const handleContextMenu = (event) => {
  //     event.preventDefault();
  //   };

  //   const handleSelectStart = (event) => {
  //     event.preventDefault();
  //   };

  //   const handleKeyDown = (event) => {
  //     if (
  //       (event.ctrlKey && event.key === "p") ||
  //       event.key === "PrintScreen" ||
  //       (event.ctrlKey && event.shiftKey && event.key === "s") ||
  //       (event.metaKey && event.shiftKey && event.key === "4") ||
  //       (event.metaKey && event.shiftKey && event.key === "3")
  //     ) {
  //       event.preventDefault();
  //     }
  //   };
  //   document.addEventListener("copy", handleCopy);
  //   document.addEventListener("contextmenu", handleContextMenu);
  //   document.addEventListener("selectstart", handleSelectStart);
  //   document.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     document.removeEventListener("copy", handleCopy);
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //     document.removeEventListener("selectstart", handleSelectStart);
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    const handleKeyDown = (event) => {
      if (
        event.keyCode === 123 ||
        (event.ctrlKey && event.shiftKey && event.keyCode === 73) ||
        (event.ctrlKey && event.shiftKey && event.keyCode === 74) ||
        (event.ctrlKey && event.keyCode === 85)
      ) {
        event.preventDefault();
      }
    };

    const detectDevTools = () => {
      const threshold = 160;
      const widthThreshold = window.outerWidth - window.innerWidth > threshold;
      const heightThreshold =
        window.outerHeight - window.innerHeight > threshold;

      if (widthThreshold || heightThreshold) {
        window.location.reload();
      }
    };
    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);
    const interval = setInterval(detectDevTools, 1000);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      {!isLoginRoute &&
        (isAdminRoute ? (
          <EventsTable sidebar={sidebar} showSidebar={showSidebar} />
        ) : (
          <>
            <Navbar />
            <EnquiryForm />
          </>
        ))}
      {pageLogin === true ? (
        <Login setPageLogin={setPageLogin} />
      ) : (
        <Routes>
          <Route
            path="/login"
            element={<Login setPageLogin={setPageLogin} />}
          />
          <Route
            path="/event_formDetails"
            element={<EventsForm showSidebar={closeSidebar} />}
          />
          <Route
            path="/ticker_header"
            element={<Ticker showSidebar={closeSidebar} />}
          />
          <Route
            path="/faqs_section"
            element={<Faq showSidebar={closeSidebar} />}
          />
          <Route
            path="/comment_list"
            element={<Comment showSidebar={closeSidebar} />}
          />
          <Route
            path="/update_info"
            element={<UpdateInfo showSidebar={closeSidebar} />}
          />
          <Route
            path="/gallery_image"
            element={<GalleryAdmin showSidebar={closeSidebar} />}
          />
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/events" element={<EventForm />} />
          <Route path="/siddhi_maa" element={<Information />} />
          <Route path="/maharaj_ji" element={<InformationDetails />} />
          <Route path="/other" element={<DonationForm />} />
          <Route path="/maharaj_gallery" element={<Maharaj />} />
          <Route path="/SriSiddhimaa_gallery" element={<Sidhimaa />} />
          <Route path="/KainchiDham_gallery" element={<KaichiDham />} />
        </Routes>
      )}
      {!isAdminRoute && !isLoginRoute && <Footer />}
    </>
  );
}
function App() {
  return (
    <Router>
      <ScrollTop />
      <div
      //  style={{ position: "relative", userSelect: "none" }}
      >
        <AppContent />
      </div>
      <ToastContainer
        position="top-left"
        autoClose={1000}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        theme="light"
      />
    </Router>
  );
}
export default App;
