import React, { useState, useEffect } from "react";
import { Element } from "react-scroll";
import PaginationTop from "../../PaginationTop";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import CustomerSlider from "./ImageSliderComponents/CustomerSlider";
import { API_HEADER, GetGalleryImageList } from "../utiles/api_constant";

const Gallery = () => {
  const { t } = useTranslation();
  const [lgShow, setLgShow] = useState(false);
  const [currentGallery, setCurrentGallery] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const api_url = GetGalleryImageList;
  const api_header = API_HEADER;
  const [sidhiImage, setSidhi] = useState([]);
  const [maharajImage, setMaharaj] = useState([]);
  const [kaichImage, setKainchi] = useState([]);
  // siddhi maa api image
  useEffect(() => {
    const fetchData = async () => {
      const url = api_url;
      const headers = api_header;
      const data = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        dataGroup: "SRI-SIDDHI-MAA",
      };
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const responseData = await response.json();
          const respo = responseData?.data;
          setSidhi(respo?.imageList || []);
        } else {
          throw new Error(
            `Request failed with status code: ${response.status}`()
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);
  // maharaj ji image list

  useEffect(() => {
    const fetchData = async () => {
      const url = api_url;
      const headers = api_header;
      const data = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        dataGroup: "MAHARAJ-JI",
      };
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const responseData = await response.json();
          const respo = responseData?.data;
          setMaharaj(respo?.imageList || []);
        } else {
          throw new Error(
            `Request failed with status code: ${response.status}`()
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);
  // mandir  image List

  useEffect(() => {
    const fetchData = async () => {
      const url = api_url;
      const headers = api_header;
      const data = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        dataGroup: "KAINCHI-DHAM-MANDIR",
      };
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const responseData = await response.json();
          const respo = responseData?.data;
          setKainchi(respo?.imageList || []);
        } else {
          throw new Error(
            `Request failed with status code: ${response.status}`()
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);

  const allImages = {
    KainchiDham_gallery: kaichImage
      ?.filter((itemList) => itemList.imageStatus === "A")
      ?.map((img) => img.azureUri),
    maharaj_gallery: maharajImage
      ?.filter((itemList) => itemList.imageStatus === "A")
      ?.map((img) => img.azureUri),
    SriSiddhimaa_gallery: sidhiImage
      ?.filter((itemList) => itemList.imageStatus === "A")
      ?.map((img) => img.azureUri),
  };
  const allTitles = {
    KainchiDham_gallery: t("header.nav7"),
    maharaj_gallery: t("header.nav1"),
    SriSiddhimaa_gallery: t("header.nav2"),
  };
  const handleImageClick = (galleryKey, index) => {
    setCurrentGallery(allImages[galleryKey]);
    setCurrentIndex(index);
    setLgShow(true);
  };

  return (
    <div>
      <PaginationTop />
      <div className="karauli_maharaj_mn">
        <div className="container">
          <h2>{t("header.nav4")}</h2>
          <hr className="line" />
        </div>
      </div>
      <div className="gallery-section">
        <Element name="home" className="element">
          <GallerySection
            title={allTitles.KainchiDham_gallery}
            galleryKey="KainchiDham_gallery"
            images={allImages.KainchiDham_gallery}
            handleImageClick={handleImageClick}
          />
        </Element>
        <Element name="about" className="element">
          <GallerySection
            title={allTitles.maharaj_gallery}
            galleryKey="maharaj_gallery"
            images={allImages.maharaj_gallery}
            handleImageClick={handleImageClick}
          />
        </Element>
        <Element name="contact" className="element">
          <GallerySection
            title={allTitles.SriSiddhimaa_gallery}
            galleryKey="SriSiddhimaa_gallery"
            images={allImages.SriSiddhimaa_gallery}
            handleImageClick={handleImageClick}
          />
        </Element>
      </div>

      <Modal
        className="bc_clr"
        size="lg modl_wd"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <CustomerSlider activeIndex={currentIndex}>
            {currentGallery?.map((item, index) => (
              <>
                <img src={item} key={index} alt={`Slide ${index}`} />
              </>
            ))}
          </CustomerSlider>
        </Modal.Body>
      </Modal>
    </div>
  );
};
const GallerySection = ({ title, galleryKey, images, handleImageClick }) => {
  return (
    <div className="gallery-section">
      <div className="text-center mrj_mn">
        <div className="container">
          <h2>{title}</h2>
          <hr className="line" />
        </div>
      </div>
      <div className="gallery_mn_top">
        <div className="container">
          <div className="gallery_mn">
            <div className="row">
              {images?.map((imgSrc, index) => (
                <div className="col-md-4 col-sm-6 col-xs-12" key={index}>
                  <img
                    className="gallery-img"
                    src={imgSrc}
                    alt={`Gallery Image ${index + 1}`}
                    onClick={() => handleImageClick(galleryKey, index)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Gallery;
