import React, { useEffect, useState } from "react";
import {
  cilTrash,
  cibStatuspage,
  cibDocusign,
  cilLowVision,
  cilPencil,
  cilImage,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  API_HEADER,
  EventList,
  DeleteDocument,
  SaveEvent,
} from "../../../../../Components/utiles/api_constant";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import { Chip, Stack } from "@mui/material";
import moment from "moment";
import { useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import {
  CButton,
  CCard,
  CCardHeader,
  CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CForm,
  CFormInput,
  CFormLabel,
} from "@coreui/react";

const Colors = ({ showSidebar }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uniquekey = searchParams.get("showSidebar");

  const [visible, setVisible] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [formShow, setFormShow] = useState(false);

  const [uniqueId, setUniqueId] = useState("");
  const [ImagePopUp, setImagePopUp] = useState(false);
  const [imgShow, setImgShow] = useState("");
  const [refreshEventList, setRefereshList] = useState(false);
  const [selectRow, setSelectRow] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [text, setText] = useState("");
  const [description, setDescription] = useState("");
  const api_url = EventList;
  const api_url2 = DeleteDocument;
  const api_url3 = SaveEvent;
  const api_header = API_HEADER;
  const handleFormShow = () => {
    setFormShow(true);
  };

  const columns = [
    {
      headerName: "S.No",
      field: "eventUniqueSerial",
      flex: 1,
    },
    {
      headerName: "Event Name",
      field: "eventHeadingText",
      flex: 1,
    },
    {
      headerName: "Start Date",
      field: "eventStartDate",

      flex: 1,
    },
    {
      headerName: "End Date",
      field: "eventEndDate",
      editable: true,
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: "Event Description",
      field: "eventHeadingDescription",
      width: 100,
      flex: 1,
    },

    {
      field: "eventStatus",
      headerName: "Status",

      flex: 1,
      renderCell: (params) =>
        params.row.eventStatus === "A" ? (
          <>
            <Chip label="Approved" color="success" size="small" />
          </>
        ) : (
          <Chip label="UnApproved" color="warning" size="small" />
        ),
    },

    {
      headerName: "Image",
      field: "azureUri",

      flex: 1,

      renderCell: (params) => (
        <GridActionsCellItem
          icon={<CIcon icon={cilImage} />}
          onClick={(e) => {
            let row = params.row;
            ImageShow(row.eventUniqueSerial);
          }}
          showInMenu
        />
      ),
    },

    {
      headerName: "Action",
      field: "Action",
      type: "actions",
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<CIcon icon={cilPencil} />}
          label="Edit"
          onClick={() => {
            let row = params.row;
            editEvent(row?.uniqueSerial);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<CIcon icon={cibStatuspage} />}
          label=" Show"
          onClick={() => handleEventStatusChange(params.row, "A")}
          showInMenu
        />,

        <GridActionsCellItem
          icon={<CIcon icon={cilLowVision} />}
          label=" Hide"
          onClick={() => handleEventStatusChange(params.row, "P")}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<CIcon icon={cibDocusign} />}
          label="Add Image"
          onClick={() => {
            let row = params.row;
            getSerialNumber(row.eventUniqueSerial);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          // icon={cilTrash}
          label="Delete Image"
          icon={<CIcon icon={cilTrash} />}
          onClick={() => handleDeleteEvent(params.row.uniqueSerial)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete Event"
          icon={<CIcon icon={cilTrash} />}
          onClick={() => handleDeleteEvent(params.row)}
          showInMenu
        />,
      ],
    },
  ];
  const handleFormHide = () => {
    setFormShow(false);
  };

  // status change

  const handleEventStatusChange = async (eventData, eventStatus) => {
    try {
      const api_param = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        eventStatus,
        eventUniqueSr: eventData?.eventUniqueSerial,
        headText: eventData?.eventHeadingText,
        headDescription: eventData?.eventHeadingDescription,
        startDate: moment(eventData?.eventStartDate).format("DD-MMM-YYYY"),
        endDate: moment(eventData?.eventEndDate).format("DD-MMM-YYYY"),
        action: "M",
      };
      const response = await fetch(api_url3, {
        method: "POST",
        body: JSON.stringify(api_param),
        headers: api_header,
      });
      if (!response.ok) throw new Error("Network response was not ok");
      await response.json();
      toast.success("Event status changed successfully!");
      setRefereshList(true);
    } catch (error) {
      toast.error(error.message);
    }
  };
  // image delete
  const handleDeleteEvent = async (eventData) => {
    try {
      const api_param = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        eventStatus: "A",
        eventUniqueSr: eventData?.eventUniqueSerial,
        headText: eventData?.eventHeadingText,
        headDescription: eventData?.eventHeadingDescription,
        startDate: moment(eventData?.eventStartDate).format("DD-MMM-YYYY"),
        endDate: moment(eventData?.eventEndDate).format("DD-MMM-YYYY"),
        action: "D",
      };
      const response = await fetch(api_url3, {
        method: "POST",
        body: JSON.stringify(api_param),
        headers: api_header,
      });
      if (!response.ok) throw new Error("Network response was not ok");
      await response.json();
      toast.success("Event Deleted Successfully!");
      setRefereshList(true);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const getSerialNumber = (eventUniqueSerial) => {
    const product = eventList?.find(
      (item) => item.eventUniqueSerial === eventUniqueSerial
    );
    setUniqueId(product);
    setVisible(true);
  };
  const ImageShow = (eventUniqueSerial) => {
    const imageURL = eventList?.find(
      (item) => item.eventUniqueSerial === eventUniqueSerial
    );
    setImgShow(imageURL);
    setImagePopUp(true);
  };
  // edit events function
  const editEvent = (uniqueSerial) => {
    const rowSelection = eventList?.find(
      (item) => item.uniqueSerial === uniqueSerial
    );

    setSelectRow(rowSelection);
    setFormShow(true);
  };
  const [selectData, setSelectData] = useState("");
  // delete events
  const deleteEvents = (eventUniqueSerial) => {
    const selectionData = eventList?.find(
      (item) => item.eventUniqueSerial === eventUniqueSerial
    );
    // setSelectData(selectionData)
    handleEventDelete(selectData);
    // setRefereshList(true)
  };

  const handleEventDelete = (actionType) => {
    var api_param = {
      brandCode: "SKDMT",
      countryCode: "IN",
      companyId: "SKDMT",
      userId: "VINOD",
      ipAddress: "1::1",
      eventStatus: actionType,
      eventUniqueSr: selectData?.eventUniqueSerial,
      headText: selectData?.eventHeadingText,
      headDescription: selectData?.eventHeadingDescription,
      startDate: moment(selectData?.eventStartDate).format("DD-MMM-YYYY"),
      endDate: moment(selectData?.eventEndDate).format("DD-MMM-YYYY"),
      action: "D",
    };
    fetch(api_url3, {
      method: "POST",
      body: JSON.stringify(api_param),
      headers: api_header,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        toast.success("SuccessFull !!");
        setRefereshList(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // approve flag

  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileChange = (e) => {
    setSelectedFiles(Array.from(e.target.files));
  };
  // image saving api
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("BrandCode", "SKDMT");
    formData.append("CountryCode", "IN");
    formData.append("CompanyId", "SKDMT");
    formData.append("Type", "JPG");
    formData.append("UniqueSerial", uniqueId?.eventUniqueSerial);
    formData.append("FileExtn", "jpg");
    formData.append("FileTitle", uniqueId?.imageTitle);
    formData.append("FileText", "test description");
    formData.append("FileGroup", "EVENT");
    formData.append("FileName", uniqueId?.imageName);
    formData.append("LogUserId", "VINOD");
    formData.append("LogIpAddress", "1::1");
    selectedFiles.forEach((file) => {
      formData.append("image", file);
    });

    try {
      const response = await fetch(
        "https://mobile.orbitsys.com/skdmt/api/skdmt/UploadDocument",
        {
          method: "POST",
          headers: {
            "api-key": "04577BA6-3E32-456C-B528-E41E20D28D79",
          },
          body: formData,
        }
      );
      if (response.ok) {
        const data = await response.json();

        toast.success("Upload successful!");
        setVisible(false);
        setRefereshList(true);
      } else {
        toast.error("Upload failed!");
      }
    } catch (error) {
      toast.error("Error uploading file!");
    }
  };
  // event saving api
  const handleEventSubmit = (actionType, eventStatus) => {
    var api_param = {
      brandCode: "SKDMT",
      countryCode: "IN",
      companyId: "SKDMT",
      userId: "VINOD",
      ipAddress: "1::1",
      eventStatus: "A",
      eventUniqueSr: actionType === "A" ? 0 : selectRow?.uniqueSerial,
      headText: text || selectRow?.eventHeadingText,
      headDescription: description || selectRow?.eventHeadingDescription,
      startDate:
        moment(startDate).format("DD-MMM-YYYY") ||
        moment(EventStartDate).format("DD-MMM-YYYY"),
      endDate:
        moment(endDate).format("DD-MMM-YYYY") ||
        moment(EventEndDate).format("DD-MMM-YYYY"),
      action: actionType,
    };
    fetch(api_url3, {
      method: "POST",
      body: JSON.stringify(api_param),
      headers: api_header,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        setDescription("");
        setText("");
        setEndDate("");
        setStartDate("");
        toast.success("SuccessFull !!");

        // handleFormHide()
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      const url = api_url;
      const headers = api_header;
      const data = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        Type: "ADMIN",
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });

        if (response.ok) {
          const responseData = await response.json();
          const respData = responseData?.data;
          setEventList(respData);
        } else {
          throw new Error(
            `Request failed with status code: ${response.status}`()
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [refreshEventList, selectData]);
  const EventStartDate = moment(selectRow?.eventStartDate).format("YYYY-MM-DD");
  const EventEndDate = moment(selectRow?.eventEndDate).format("YYYY-MM-DD");
  return (
    <div onClick={showSidebar}>
      <CCol xs={12}>
        <CCard className="mb-4" style={{ margin: "20px" }}>
          <CCardHeader>
            <div className="btn_tx">
              {selectRow?.length === 0 ? (
                <>
                  {" "}
                  <p>Events List</p>
                  <button
                    className="btn btn-danger add_event_btn"
                    onClick={handleFormShow}
                  >
                    Add New Events
                  </button>
                </>
              ) : (
                <>
                  <p>Update Events List</p>
                </>
              )}
            </div>
          </CCardHeader>
          {/* event creation form  */}
          {formShow === true ? (
            <div>
              <CForm>
                <div className="row">
                  <div className="col-md-6">
                    <div className="p-3">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        {" "}
                        Event Name
                      </CFormLabel>
                      <CFormInput
                        value={text || selectRow?.eventHeadingText}
                        onChange={(e) => setText(e.target.value)}
                        type="text"
                        id="exampleFormControlInput1"
                      />
                    </div>
                    <div className="p-3">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        {" "}
                        Event Description
                      </CFormLabel>
                      <CFormInput
                        value={
                          description || selectRow?.eventHeadingDescription
                        }
                        onChange={(e) => setDescription(e.target.value)}
                        type="text"
                        id="exampleFormControlInput1"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="p-3">
                      <CFormLabel htmlFor="exampleFormControlInput1">
                        {" "}
                        Event Start Date{" "}
                      </CFormLabel>
                      <CFormInput
                        type="date"
                        onChange={(e) => setStartDate(e.target.value)}
                        
                        value={startDate}
                        id="exampleFormControlInput1"
                      />
                    </div>
                    <div className="p-3">
                      <CFormLabel htmlFor="exampleFormControlTextarea1">
                        {" "}
                        Event End Date
                      </CFormLabel>
                      <CFormInput
                        type="date"
                        value={endDate || EventEndDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        id="exampleFormControlInput1"
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-success mb-3 "
                  onClick={() => {
                    selectRow.length === 0
                      ? handleEventSubmit("A")
                      : handleEventSubmit("M");
                    handleFormHide();
                  }}
                  style={{ width: "100px", marginLeft: "40%" }}
                >
                  Submit
                </button>

                <button
                  className="btn btn-secondary mb-3 "
                  onClick={() => {
                    handleFormHide();
                  }}
                  style={{ width: "100px", marginLeft: "1%" }}
                >
                  Cancel
                </button>
              </CForm>
            </div>
          ) : (
            <>
              <div style={{ height: 500, width: "100%" }}>
                <DataGrid
                  rows={eventList}
                  getRowId={(r) => r.eventUniqueSerial}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 20 },
                    },
                  }}
                 
                />
              </div>
            </>
          )}
        </CCard>
      </CCol>

      {/* add Image popup */}
      <>
        <CModal
          alignment="center"
          visible={visible}
          onClose={() => setVisible(false)}
        >
          <CModalHeader>
            <CModalTitle>Modal title</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <div className="col-md-6">
              {" "}
             
              <label>Image Upload</label>
              <input
                onChange={handleFileChange}
                className="form-control form_styl1"
                type="file"
                placeholder="Image"
                accept="image/*"
              />
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={() => setVisible(false)}>
              Close
            </CButton>
            <CButton color="primary" onClick={handleSubmit}>
              Save
            </CButton>
          </CModalFooter>
        </CModal>
        {/* show Image Popup */}
        <CModal
          alignment="center"
          visible={ImagePopUp}
          onClose={() => setImagePopUp(false)}
        >
          <CModalHeader>
           
          </CModalHeader>
          <CModalBody>
            {imgShow?.azureUri === "" ? (
              <h3>Not Data Found</h3>
            ) : (
              <img
                style={{ height: "200px", width: "100%" }}
                src={imgShow?.azureUri}
              />
            )}
          </CModalBody>
        </CModal>
      </>
    </div>
  );  
};

export default Colors;
