import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import logo from "../../../../Components/Image/logo.png";
import EventsForm from "../dashoard/Events/EventsForm";
import "./nav.css";
import image1 from "../../../../Components/Image/Picture 3 ( D ).jpg";
import image2 from "../../../../Components/Image/Picture 3 (A).jpg";
import image3 from "../../../../Components/Image/Picture 4 (a).jpg";
import image4 from "../../../../Components/Image/Kainchi/Kainchi/img1.jpg";
import image5 from "../../../../Components/Image/Kainchi/Kainchi/img2.jpg";
import image6 from "../../../../Components/Image/Kainchi/Kainchi/img4.jpg";
import image7 from "../../../../Components/Image/Kainchi/Kainchi/img6.jpg";
import Ticker from "../dashoard/Events/Ticker";
import UpdateInfo from "../dashoard/Events/UpdateInfo";
import Gallery from "../dashoard/Events/GalleryAdmin";
import VisitorCounter from "../../../../VisitorCounter";
import {
  GetVisitorCount,
  API_HEADER,
} from "../../../../Components/utiles/api_constant";
const Nav = styled.div`
  background: #15171c;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const SidebarNav = styled.nav`
  background: #15171c;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
`;
const SidebarWrap = styled.div`
  width: 100%;
`;


const Sidebar = ({showSidebar,sidebar}) => {

  const [count, setCount] = useState(0);
 
  const api_url = GetVisitorCount;
  const api_header = API_HEADER;
  useEffect(() => {
    const fetchData = async () => {
      const url = api_url;
      const headers = api_header;
      const data = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
      };
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const responseData = await response.json();
          const respo = responseData?.data;
          setCount(respo);
        } else {
          throw new Error(
            `Request failed with status code: ${response.status}`()
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="bdy2">
        <IconContext.Provider value={{ color: "#fff" }}>
          <Nav>
            <NavIcon to="#">
              <FaIcons.FaBars onClick={showSidebar} />
            </NavIcon>
            <h1 className="hd_tx_top">Sri Kainchi Mandir</h1>
            <span className="hd_tx_top22">
              Visitor Count: {""}
              <p className="count_number">
                {Number(count?.totalVisitor) + 2079}{" "}
              </p>{" "}
            </span>
          </Nav>
          <SidebarNav sidebar={sidebar}>
            <SidebarWrap>
              <NavIcon to="#">
                <AiIcons.AiOutlineClose onClick={showSidebar} />
              </NavIcon>
              {SidebarData?.map((item, index) => {
                return (
                  <SubMenu item={item} key={index} showSidebar={showSidebar} />
                );
              })}
            </SidebarWrap>
          </SidebarNav>
        </IconContext.Provider>
      </div>
    </>
  );
};
export default Sidebar;
