import React from "react";
import img_gallery from "../../../../Components/Image/gallery 1.png";
import img_update from "../../../../Components/Image/updated.png"
import question from "../../../../Components/Image/question-sign.png"
import ribbon from "../../../../Components/Image/ribbon.png"
import logout from "../../../../Components/Image/logout.png"
import faqs from "../../../../Components/Image/faqs.png"

export const SidebarData = [
  // {
  //   title: "Home",
  //   path: "/event_formDetails",
  //   icon: <AiIcons.AiFillHome />,
  // },
  // {
  //   title: "Event",
  //   path: "/event_formDetails",
  //   icon: <IoIcons.IoIosPaper />,
  // },
  {
    title: "BANNER",
    path: "/ticker_header",
    icon: <img style={{width:"30px"}} src={ribbon} />,
  },
  {
    title: "Update",
    path: "/update_info",
    icon:  <img style={{width:"30px"}} src={img_update} />,
  },
  {
    title: "Gallery",
    path: "/gallery_image",
    icon: <img style={{width:"30px"}} src={img_gallery} />,
  },

  // {
  //   title: "Comment",
  //   path: "/comment_list",
  //   icon:  <img style={{width:"30px"}} src={question} />,
  // },


  {
    title: "FAQs",
    path: "/faqs_section",
    icon:  <img style={{width:"30px"}} src={faqs} />,
  },

  {
    title: "Logout",
    path: "/login",
    icon:  <img style={{width:"30px"}} src={logout} />,
  },
];
