import React, { useState } from "react";
import { toast } from "react-toastify";

function App() {
  const [image, setImage] = useState("");
  const [video, setVideo] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [name, setName] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (e.target.placeholder === "Image") {
        setImage(URL.createObjectURL(file));
      } else if (e.target.placeholder === "vedio") {
        setVideo(URL.createObjectURL(file));
      }
      validateForm();
    }
  };

  const handleLinkChange = (e) => {
    setYoutubeLink(e.target.value);
    validateForm();
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    validateForm();
  };

  const validateForm = () => {
    setIsFormValid(!!name && (image || video));
  };

  const handleUpload = () => {
    console.log(isFormValid, "mmm");
    if (!isFormValid) {
      toast.error("Please fill out the form correctly.");
      return;
    }
    toast.success("Uploaded Data SuccessFully !");
    console.log(isFormValid, "mmm");

    console.log("Upload button clicked");
    console.log("YouTube Link:", youtubeLink);
    console.log("Name:", name);
  };

  const getYoutubeEmbedUrl = (url) => {
    const videoId = url.split("v=")[1];
    const ampersandPosition = videoId ? videoId.indexOf("&") : -1;
    return ampersandPosition === -1
      ? videoId
      : videoId.substring(0, ampersandPosition);
  };

  return (
<div>

    {/* <div className="container">
      <h1>Upload Image, Video, and YouTube Link</h1>
      <label>Name</label>
      <input
        className="form-control form_styl1"
        type="text"
        placeholder="name"
        value={name}
        onChange={handleNameChange}
      />
      <label>Image Upload</label>
      <input
        onChange={handleChange}
        className="form-control form_styl1"
        type="file"
        placeholder="Image"
        accept="image/*"
      />
      <label>Video Upload</label>
      <input
        className="form-control form_styl1"
        type="file"
        placeholder="vedio"
        onChange={handleChange}
        accept="video/*"
      />
      <label>YouTube Link</label>
      <input
        className="form-control form_styl1"
        type="text"
        placeholder="YouTube Link"
        value={youtubeLink}
        onChange={handleLinkChange}
      />
      <button
        type="button"
        className="btn btn-success"
        onClick={handleUpload}
        disabled={!isFormValid}
      >
        Upload
      </button>

      <div className="App">
        {image && (
          <div>
            <h2>Preview Image:</h2>
            <img
              src={image}
              alt="Selected"
              style={{ width: "300px", height: "auto" }}
            />
          </div>
        )}
        {video && (
          <div>
            <h2>Preview Video:</h2>
            <video width="320" height="240" controls>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
        {youtubeLink && (
          <div>
            <h2>Preview YouTube Video:</h2>
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${getYoutubeEmbedUrl(
                youtubeLink
              )}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube Video"
            ></iframe>
          </div>
        )}
      </div>
    </div> */}

    </div>
  );
}

export default App;
