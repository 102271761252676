import React, { useEffect, useState } from "react";
import {
  cilTrash,
  cibStatuspage,
  cibDocusign,
  cilLowVision,
  cilPencil,
  cilImage,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  API_HEADER,
  GetPageData,
  SaveEvent,
  SavePageData,
} from "../../../../../Components/utiles/api_constant";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import { Chip, Stack } from "@mui/material";
import moment from "moment";

import { toast } from "react-toastify";
import {
  CCard,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CFormLabel,
} from "@coreui/react";

const Ticker = ({ showSidebar }) => {
  // model popup
  const [visible, setVisible] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [formShow, setFormShow] = useState(false);
  const api_url = GetPageData;
  const api_url3 = SaveEvent;
  const api_url4 = SavePageData;
  const api_header = API_HEADER;
  const [refreshEventList, setRefereshList] = useState(false);
  const [text, setText] = useState("");
  const [description, setDescription] = useState("");

  // useEffect(() => {
  //   setText(eventList?.pageDataHindi);
  //   setDescription(eventList?.pageData);
  // }, [eventList]);

  const handleFormShow = () => {
    setFormShow(true);
  };
  const handleFormHide = () => {
    setText("");
    setDescription("");
  };
  useEffect(() => {
    const fetchData = async () => {
      const url = api_url;
      const headers = api_header;
      const data = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        pageUniqueSerial: 0,
        pageCode: "BANNER", //"MISC",//UPDATES,BANNER
        calledBy: "ALL", // ALL,EDIT,WEB
        pageDataHindi: null,
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });

        if (response.ok) {
          const responseData = await response.json();
          const respo = responseData?.data;
          setEventList(respo?.pageDataList[0]);
        } else {
          throw new Error(
            `Request failed with status code: ${response.status}`()
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [refreshEventList]);
  // ticker saving api
  const handleTickerSubmit = (actionType, eventStatus) => {
    var api_param = {
      brandCode: "SKDMT",
      countryCode: "IN",
      companyId: "SKDMT",
      userId: "VINOD",
      ipAddress: "1::1",
      pageUniqueSerial: 9,
      displayOrder: 1,
      status: "A",
      action: "M",
      pageCode: "BANNER",
      pageData: description || eventList?.pageData,
      pageDataHindi: text || eventList?.pageDataHindi,
    };
    fetch(api_url4, {
      method: "POST",
      body: JSON.stringify(api_param),
      headers: api_header,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        setText("");
        setDescription("");
        handleFormHide();
        setRefereshList(!refreshEventList);
        toast.success("SuccessFull !!");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div onClick={showSidebar}>
      <CCol xs={12}>
        <CCard className="mb-4" style={{ margin: "20px" }}>
          <CCardHeader>
            <div className="btn_tx">
              <>
                <p>BANNER </p>
              </>
            </div>
          </CCardHeader>
          <div>
            <CForm>
              <div className="row">
                <div className="col-md-4">
                  <div className="p-3">
                    <CFormLabel htmlFor="exampleFormControlTextarea1">
                      {" "}
                      Current English Text
                    </CFormLabel>
                    <textarea
                      className="form-control fom_ht"
                      value={eventList?.pageData}
                      disabled={true}
                      type="text"
                      id="exampleFormControlInput1"
                    />
                  </div>

                  <div className="p-3">
                    <CFormLabel htmlFor="exampleFormControlTextarea1">
                      {" "}
                      Current Hindi Text
                    </CFormLabel>
                    <textarea
                      className="form-control fom_ht"
                      value={eventList?.pageDataHindi}
                      disabled={true}
                      type="text"
                      id="exampleFormControlInput1"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="p-3">
                    <CFormLabel htmlFor="exampleFormControlTextarea1">
                      {" "}
                      New English Text
                    </CFormLabel>
                    <textarea
                      className="form-control fom_ht"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      type="text"
                      id="exampleFormControlInput1"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="p-3">
                    <CFormLabel htmlFor="exampleFormControlTextarea1">
                      {" "}
                      New Hindi Text
                    </CFormLabel>
                    <textarea
                      className="form-control fom_ht"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      type="text"
                      id="exampleFormControlInput1"
                    />
                  </div>
                </div>
              </div>

              <div className="btn_form_mn">
                <button
                  className="sv_btn"
                  onClick={() => {
                    handleTickerSubmit();
                  }}
                >
                  Save
                </button>

                <button className="cn_btn" onClick={handleFormHide}>
                  Reset
                </button>
              </div>
            </CForm>
          </div>
        </CCard>
      </CCol>
      <></>
    </div>
  );
};
export default Ticker;
