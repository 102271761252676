import Nav from "react-bootstrap/Nav";
import { NavLink, Bars, NavMenu, NavBtn, NavBtnLink } from "./NavbarEvents";
import Navbar from "react-bootstrap/Navbar";
import React, { useState, useEffect } from "react";
import logoImg from "../Image/logo.png";
import { useTranslation } from "react-i18next";
import "./navbar.css";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll, scroller } from "react-scroll";

function BasicExample() {
  const [expanded, setExpanded] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t } = useTranslation();
  const handleNavLinkClick = () => {
    setExpanded(false);
  };

  const navigate = useNavigate();

  const handleGalleryNavClick = (galleryType) => {
    navigate("/gallery");
    // setExpanded(false);
  };
  const [fontSize, setFontSize] = useState("16px");
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const handleFontSizeChange = (event) => {
    setFontSize(event.target.value);
    setExpanded(false);
  };
  useEffect(() => {
    document.documentElement.style.fontSize = fontSize;
  }, [fontSize]);
  useEffect(() => {
    document.documentElement.style.fontSize = fontSize;
  }, [fontSize]);

  const offsetMap = {
    home: 100,
    about: 1200,
    contact: 2500,
  };

  const handleScroll = (value) => {
    const offset = offsetMap[value] || 0;
    scroller.scrollTo(value, {
      duration: 300,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: offset,
    });
    setExpanded(false);
  };

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  return (
    <Navbar
      expand="lg"
      className="bg-body-tertiary header_fix1"
      expanded={expanded}
    >
      <div className="container">
        <>
          <div className="col-md-12">
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setExpanded(!expanded)}
            />
            <NavLink to="/" onClick={handleNavLinkClick}>
              <img className="logo_mob" src={logoImg} />
            </NavLink>
            <Navbar.Collapse className="nv_rit" id="basic-navbar-nav">
              <Nav className="me-auto nav_header1">
                <NavLink to="/maharaj_ji" onClick={handleNavLinkClick}>
                  {t("header.nav1")}
                </NavLink>
                <NavLink to="/siddhi_maa" onClick={handleNavLinkClick}>
                  {t("header.nav2")}
                </NavLink>
                <NavLink to="/about" onClick={handleNavLinkClick}>
                  {t("header.nav3")}
                </NavLink>
                <div className="logo_pic">
                  <NavLink to="/" onClick={handleNavLinkClick}>
                    <img src={logoImg} />
                  </NavLink>
                </div>

                <NavDropdown
                  title={t("header.nav4")}
                  id="nav-dropdown"
                  show={dropdownOpen}
                  onClick={handleGalleryNavClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <NavDropdown.Item
                    className="drp_glery"
                    onClick={() => handleScroll("home")}
                  >
                    {t("header.nav7")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="drp_glery"
                    onClick={() => handleScroll("about")}
                  >
                    {t("header.nav1")}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="drp_glery"
                    onClick={() => handleScroll("contact")}
                  >
                    {t("header.nav2")}
                  </NavDropdown.Item>
                </NavDropdown>

                {/*  
<NavDropdown title={t("header.nav4")} id="nav-dropdown" onChange={(e) => handleScroll(e.target.value)}   onClick={handleGalleryNavClick} >
  <NavDropdown.Item className="drp_glery" value="home">
    {t("header.nav7")}
  </NavDropdown.Item>
  <NavDropdown.Item className="drp_glery"  value="about">
    {t("header.nav1")}
  </NavDropdown.Item>
  <NavDropdown.Item className="drp_glery"  value="contact">
    {t("header.nav2")}
  </NavDropdown.Item>
</NavDropdown> */}

                {/* <NavLink to="/events">Events</NavLink>
                <NavLink to="/other">Other</NavLink> */}
                <NavLink to="/events" onClick={handleNavLinkClick}>
                  {" "}
                  {t("header.nav5")}
                </NavLink>
                <NavLink to="/contact" onClick={handleNavLinkClick}>
                  {" "}
                  {t("header.nav6")}
                </NavLink>
                {/* <NavLink to="/contact"> contact Us</NavLink> */}

                <div className="drp_styl">
                  <select
                    className="size_class"
                    id="font-size-selector"
                    value={fontSize}
                    onChange={handleFontSizeChange}
                  >
                    <option className="dru_clr" value="13px">
                      A-
                    </option>
                    <option className="dru_clr" value="15px">
                      A
                    </option>
                    <option className="dru_clr" value="18px">
                      A+
                    </option>
                  </select>

                  {/* <button
                  className="btn btn-danger btn_eng"
                  onClick={() => changeLanguage("en")}
                >
                  English
                </button>
                <button
                  className="btn btn-secondary btn_hindi"
                  onClick={() => changeLanguage("hi")}
                >
                  हिन्दी
                </button> */}
                  {/* <div id="google_translate_element"></div> */}
                  <select
                    onChange={(e) => {
                      changeLanguage(e.target.value);

                      handleNavLinkClick(false);
                    }}
                    // onClick={()=>handleNavLinkClick(false)}
                    className="size_class"
                  >
                    <option className="dru_clr" value="en">
                      English
                    </option>
                    <option className="dru_clr" value="hi">
                      हिन्दी
                    </option>
                  </select>
                </div>
              </Nav>
            </Navbar.Collapse>
          </div>
        </>
      </div>
    </Navbar>
  );
}

export default BasicExample;
