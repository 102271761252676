import React, { useEffect, useState } from "react";
import CIcon from "@coreui/icons-react";
import {
  cilTrash,
  cibStatuspage,
  cilLowVision,
} from "@coreui/icons";
import {
  API_HEADER,
  DeleteDocument,
  GetGalleryImageList,
  DocumentHideShow,
} from "../../../../../Components/utiles/api_constant";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import { CCard, CCardHeader, CCol, CForm, CFormLabel } from "@coreui/react";
const Gallery = ({ showSidebar }) => {
  const api_url = GetGalleryImageList;
  const api_url2 = DeleteDocument;
  const api_url4 = DocumentHideShow;
  const api_header = API_HEADER;
  const [refreshEventList, setRefereshList] = useState(false);
  const [groupType, setGroupType] = useState("");
  const [sidhiImage, setSidhi] = useState([]);
  const [maharajImage, setMaharaj] = useState([]);
  const [kaichImage, setKainchi] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false); 
  // Image delete
  const handleDeleteEvent = async (uniqueSerial) => {
    var api_param = {
      brandCode: "SKDMT",
      countryCode: "IN",
      companyId: "SKDMT",
      userId: "VINOD",
      ipAddress: "1::1",
      uniqueSerial: uniqueSerial,
    };
    setIsLoading(true); 
    try {
      const response = await fetch(api_url2, {
        method: "POST",
        body: JSON.stringify(api_param),
        headers: api_header,
      });
      if (response.ok) {
        const data = await response.json();
        toast.success("Image Deleted Successfully!");
        setRefereshList(!refreshEventList);
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    } finally {
      setIsLoading(false); 
    }
  };
  const handleImageApprove = async (uniqueSerial, status) => {
    var api_param = {
      brandCode: "SKDMT",
      countryCode: "IN",
      companyId: "SKDMT",
      userId: "VINOD",
      ipAddress: "1::1",
      uniqueSerial: uniqueSerial,
      imageStatus: status, // A,H
    };
    setIsLoading(true);
    try {
      const response = await fetch(api_url4, {
        method: "POST",
        body: JSON.stringify(api_param),
        headers: api_header,
      });
      if (response.ok) {
        const data = await response.json();
        toast.success("Image Status Update!");
        setRefereshList(!refreshEventList);
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFileChange = (e) => {
    setSelectedFiles(Array.from(e.target.files));
  };
  // Image saving api
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!groupType || selectedFiles.length === 0) {
      toast.error("Please select Image Group and File");
      return;
    }
    const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
    for (let file of selectedFiles) {
      if (!validImageTypes.includes(file.type)) {
        toast.error("Only image files (JPG, PNG, GIF) are allowed.");
        return;
      }
    }
    const formData = new FormData();
    formData.append("BrandCode", "SKDMT");
    formData.append("CountryCode", "IN");
    formData.append("CompanyId", "SKDMT");
    formData.append("Type", "JPG");
    formData.append("UniqueSerial", 0);
    formData.append("FileExtn", "jpg");
    formData.append("FileTitle", "testing");
    formData.append("FileText", "test description");
    formData.append("FileGroup", groupType);
    formData.append("FileName", "testing image");
    formData.append("LogUserId", "VINOD");
    formData.append("LogIpAddress", "1::1");
    selectedFiles.forEach((file) => {
      formData.append("image", file);
    });

    setIsLoading(true); // Set loading state to true

    try {
      const response = await fetch(
        "https://mobile.orbitsys.com/skdmt/api/skdmt/UploadDocument",
        {
          method: "POST",
          headers: {
            "api-key": "04577BA6-3E32-456C-B528-E41E20D28D79",
          },
          body: formData,
        }
      );
      if (response.ok) {
        const data = await response.json();
     
        toast.success("Image Upload successfully!");
        setRefereshList(!refreshEventList);
        setGroupType("");
        setSelectedFiles([]);
      } else {
        toast.error("Upload failed!");
      }
    } catch (error) {
      toast.error("Error uploading file!");
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };
  // Siddhi Maa api image
  useEffect(() => {
    const fetchData = async () => {
      const url = api_url;
      const headers = api_header;
      const data = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        dataGroup: "SRI-SIDDHI-MAA",
      };
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const responseData = await response.json();
          const respo = responseData?.data;
          setSidhi(respo?.imageList || []);
        } else {
          throw new Error(
            `Request failed with status code: ${response.status}`
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [refreshEventList]);
  // Maharaj Ji image list
  useEffect(() => {
    const fetchData = async () => {
      const url = api_url;
      const headers = api_header;
      const data = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        dataGroup: "MAHARAJ-JI",
      };
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const responseData = await response.json();
          const respo = responseData?.data;
          setMaharaj(respo?.imageList || []);
        } else {
          throw new Error(
            `Request failed with status code: ${response.status}`
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [refreshEventList]);
  // Mandir image List
  useEffect(() => {
    const fetchData = async () => {
      const url = api_url;
      const headers = api_header;
      const data = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        dataGroup: "KAINCHI-DHAM-MANDIR",
      };
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const responseData = await response.json();
          const respo = responseData?.data;
          setKainchi(respo?.imageList || []);
        } else {
          throw new Error(
            `Request failed with status code: ${response.status}`
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [refreshEventList]);
  const imagetype = [
    { id: 1, name: "KAINCHI-DHAM-MANDIR" },
    { id: 2, name: "MAHARAJ-JI" },
    { id: 3, name: "SRI-SIDDHI-MAA" },
  ];
  return (
    <div onClick={showSidebar}>
      <CCol xs={12}>
        <CCard className="mb-4" style={{ margin: "20px" }}>
          <CCardHeader>
            <div className="btn_tx">
              <>
                <p>Gallery</p>
              </>
            </div>
          </CCardHeader>
          {/* Event creation form */}
          <div>
            <CForm>
              <div className="row">
                <div className="col-md-6">
                  <div className="p-3">
                    <CFormLabel htmlFor="exampleFormControlTextarea1">
                      Select Image Group
                    </CFormLabel>
                    <select
                      className="form-control "
                      value={groupType}
                      onChange={(e) => setGroupType(e.target.value)}
                    >
                      <option>Select</option>
                      {imagetype?.map((item) => (
                        <option value={item?.name}>{item?.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="p-3">

                  <CFormLabel htmlFor="exampleFormControlTextarea1">
                      Select Image 
                    </CFormLabel>
                   
                    <input
                      onChange={handleFileChange}
                      className="form-control form_styl1"
                      type="file"
                      placeholder="Image"
                      accept="image/*"
                    />
                  </div>
                </div>
              </div>
              <div className="btn_form_mn">
                <button className="sv_btn" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </CForm>
          </div>
        </CCard>
      </CCol>
      <div className="imgage_show m-3">
        <div className="">
          {isLoading && (
            <div className="loading-container">
              <ReactLoading
                type="spin"
                color="#9f0101 "
                height={100}
                width={100}
              />
            </div>
          )}
          <>
            <div className="galer_bg_clr">
              <div>
                <h4>KAINCHI-DHAM-MANDIR</h4>
                <div className="row">
                  {kaichImage?.map((imgList) => (
                    <div className="col-md-3" key={imgList?.uniqueSerial}>
                      <img
                        src={imgList?.azureUri}
                        className={`img_list_gallery ${imgList?.imageStatus === "H" ? "img_blur" : ""}`}
                        alt="Gallery"
                      />
                      <CIcon
                        className="dlt_icn"
                        icon={cilTrash}
                        onClick={() => handleDeleteEvent(imgList?.uniqueSerial)}
                      />
                      {imgList?.imageStatus === "H" && (
                        <CIcon
                          icon={cibStatuspage}
                          onClick={() =>
                            handleImageApprove(imgList?.uniqueSerial, "A")
                          }
                          style={{ width: "20px", cursor: "pointer",marginTop:"-93px" }}
                        />
                      )}
                      {imgList?.imageStatus === "A" && (
                        <CIcon
                          icon={cilLowVision}
                          style={{ width: "20px", cursor: "pointer" ,marginTop:"-93px"}}
                          onClick={() =>
                            handleImageApprove(imgList?.uniqueSerial, "H")
                          }
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="galer_bg_clr">
              <div>
                <h4>MAHARAJ-JI</h4>
                <div className="row">
                  {maharajImage?.map((imgList) => (
                    <div className="col-md-3" key={imgList?.uniqueSerial}>
                      <img
                        src={imgList?.azureUri}
                        className={`img_list_gallery ${imgList?.imageStatus === "H" ? "img_blur" : ""}`}
                        alt="Gallery"
                      />
                      <CIcon
                        className="dlt_icn"
                        icon={cilTrash}
                        onClick={() => handleDeleteEvent(imgList?.uniqueSerial)}
                      />

                      {imgList?.imageStatus === "H" && (
                        <CIcon
                          icon={cibStatuspage}
                          onClick={() =>
                            handleImageApprove(imgList?.uniqueSerial, "A")
                          }
                          style={{ width: "20px", cursor: "pointer",marginTop:"-93px" }}
                        />
                      )}
                      {imgList?.imageStatus === "A" && (
                        <CIcon
                          icon={cilLowVision}
                          style={{ width: "20px", cursor: "pointer",marginTop:"-93px" }}
                          onClick={() =>
                            handleImageApprove(imgList?.uniqueSerial, "H")
                          }
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="galer_bg_clr">
              <div>
                <h4>SRI-SIDDHI-MAA</h4>
                <div className="row">
                  {sidhiImage?.map((imgList) => (
                    <div className="col-md-3" key={imgList?.uniqueSerial}>
                      <img
                        src={imgList?.azureUri}
                        className={`img_list_gallery ${imgList?.imageStatus === "H" ? "img_blur" : ""}`}
                        alt="Gallery"
                      />
                      <CIcon
                        className="dlt_icn"
                        icon={cilTrash}
                        onClick={() => handleDeleteEvent(imgList?.uniqueSerial)}
                      />
                      {imgList?.imageStatus === "H" && (
                        <CIcon
                          icon={cibStatuspage}
                          onClick={() =>
                            handleImageApprove(imgList?.uniqueSerial, "A")
                          }
                          style={{ width: "20px", cursor: "pointer",marginTop:"-93px" }}
                        />
                      )}
                      {imgList?.imageStatus === "A" && (
                        <CIcon
                          icon={cilLowVision}
                          style={{ width: "20px", cursor: "pointer",marginTop:"-93px" }}
                          onClick={() =>
                            handleImageApprove(imgList?.uniqueSerial, "H")
                          }
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};
export default Gallery;
