import React from "react";
import siddhi_maa from "../Image/siddhi_maa.jpg";
import siddhi_maa2 from "../Image/siddhi_maa2.jpg";
import siddhi_maa3 from "../Image/siddhi_maa3.jpg";
import siddhi_maa4 from "../Image/siddhi_maa4.jpeg";
import  PaginationTop from "../../PaginationTop"
import { useTranslation } from "react-i18next";

const Information = () => {
  const { t } = useTranslation();
  return (
    <div>
      <PaginationTop />
      <div class="karauli_maharaj_mn">
        <div class="container">
          <h2>{t("SiddhiMaa.title")}</h2>
          <hr class="line"></hr>
        </div>
      </div>

      <div className="about_mn">
        <div className="container">
          <div className="about_section ">
            <div className="row">
              <div className="col-md-5 col-sm-6 col-xs-12">
                <div className="about_pic">
                  <img src={siddhi_maa} />
                </div>
              </div>

              <div className="col-md-7 col-sm-6 col-xs-12">
                <div className="about_tx">
                  <p>
                  {t("SiddhiMaa.description1")}

                  </p>
                </div>
                
              </div>

              {/* <p className="about_tx2P">
             
              </p> */}

            </div>
          </div>

          <div className="about_section padding1">
            <div className="row">
              <div className="col-md-7 col-sm-6 col-xs-12">
                <div className="about_tx">
                  <p>
                  {t("SiddhiMaa.description2")}
                  </p>
                </div>
              </div>

              <div className="col-md-5 col-sm-6 col-xs-12">
                <div className="about_pic">
                  <img className="" src={siddhi_maa2} />
                </div>
              </div>
            </div>
          </div>

          <div className="about_section padding1">
            <div className="row">
              <div className="col-md-5 col-sm-6 col-xs-12">
                <div className="about_pic">
                  <img src={siddhi_maa3} />
                </div>
              </div>

              <div className="col-md-7 col-sm-6 col-xs-12">
                <div className="about_tx">
                  <p>
                  {t("SiddhiMaa.description3")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="about_section padding1">
            <div className="row">
              <div className="col-md-7 col-sm-6 col-xs-12">
                <div className="about_tx">
                  <p className="">
                  {t("SiddhiMaa.description4")}
                  </p>
                </div>
              </div>

              <div className="col-md-5 col-sm-6 col-xs-12">
                <div className="about_pic">
                  <img className="" src={siddhi_maa4} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
