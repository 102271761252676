import React from "react";
import Navbar from "../../Navbar/Navbar";
import "./style.css";
import {
  HashRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import EventForm from "../Events/EventsForm";
import Ticker from "../Events/Ticker";
import UpdateInfo from "../Events/UpdateInfo";
import Gallery from "./GalleryAdmin";

const EventsTable = ({sidebar,showSidebar}) => {
  return (
    <div>
      <Navbar sidebar={sidebar} showSidebar={showSidebar} />
    </div>
  );
};

export default EventsTable;
