import { useContext, useState, useEffect } from "react";
import AccordionContext from "react-bootstrap/AccordionContext";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useTranslation } from "react-i18next";
import { API_HEADER, GetFAQData } from "../utiles/api_constant";
const PINK = "rgba(255, 192, 203, 0.6)";
const BLUE = "rgba(0, 0, 255, 0.6)";
function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <p
      type="text"
      style={{ border: "none", color: "white", fontSize: "20px" }}
      onClick={decoratedOnClick}
    >
      {children}
    </p>
  );
}

const FaqSection = () => {
  const { t, i18n } = useTranslation();
  const api_url = GetFAQData; // Replace with your API URL
  const api_header = API_HEADER; // Replace with your API headers
  const [marquee, setMarquee] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        fAQUniqueSerial: 0,
        calledBy: "ALL",
      };

      try {
        const response = await fetch(api_url, {
          method: "POST",
          headers: api_header,
          body: JSON.stringify(data),
        });
        
        if (response.ok) {
          const responseData = await response.json();
          const respo = responseData?.data;
          setMarquee(respo?.questionList || []);
        } else {
          throw new Error(`Request failed with status code: ${response.status}`);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [api_url, api_header]);

  return (
    <>
      <div className="accordion">
        {marquee
          ?.filter((itemList) => itemList?.status === "A") // Only show active (status: "A") items
          ?.map((itemList) => (
            <div className="accordion-item" key={itemList?.faqUniqueSerial}>
              <input
                type="checkbox"
                id={`accordion${itemList?.faqUniqueSerial}`}
              />
              <label
                htmlFor={`accordion${itemList?.faqUniqueSerial}`}
                className="accordion-item-title"
              >
                <span className="icon"></span>
                {/* Question based on language preference */}
                {i18n.language === "hi" ? (
                  <span style={{textAlign:"left"}} dangerouslySetInnerHTML={{__html:itemList?.dataQuesHindi}} />
                ) : (
                  // Use dangerouslySetInnerHTML for English HTML content
                  <span style={{textAlign:"left"}} dangerouslySetInnerHTML={{ __html: itemList?.dataQuesEng }} />
                )}
              </label>
              <div className="accordion-item-desc">
                {/* Answer based on language preference */}
                {i18n.language === "hi" ? (
                  <span dangerouslySetInnerHTML={{ __html:itemList?.dataAnsHindi}} />
                ) : (
                  // Use dangerouslySetInnerHTML for English HTML content
                  <span dangerouslySetInnerHTML={{ __html: itemList?.dataAnsEng }} />
                )}
              </div>
            </div>
          ))}
      </div>
     
    </>
  );
};
export default FaqSection;
