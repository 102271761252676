// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import TextField from "@mui/material/TextField";
// import InputAdornment from "@mui/material/InputAdornment";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import Input from "@mui/material/Input";
// import IconButton from "@mui/material/IconButton";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import {
//   CButton,
//   CCard,
//   CCardBody,
//   CCardGroup,
//   CCol,
//   CContainer,
//   CForm,
//   CRow,
// } from "@coreui/react";
// import {
//   API_HEADER,
//   UserLogin,
// } from "../../Components/utiles/api_constant";

// const Login = ({ setPageLogin }) => {
//   const [userName, setUserName] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const navigate = useNavigate();
//   const generateSessionId = () => {
//     return "xxxx-xxxx-4xxx-yxxx-xxxx".replace(/[xy]/g, function (c) {
//       var r = (Math.random() * 16) | 0,
//         v = c == "x" ? r : (r & 0x3) | 0x8;
//       return v.toString(16);
//     });
//   };
//   const handleSubmit = () => {
//     if (!userName) {
//       toast.error("Username is required");
//       return;
//     }
//     if (!password) {
//       toast.error("Password is required");
//       return;
//     }
//     const api_param = {
//       brandCode: "SKDMT",
//       countryCode: "IN",
//       companyId: "SKDMT",
//       userId: userName,
//       ipAddress: "1::1",
//       password: password,
//     };
//     fetch(UserLogin, {
//       method: "POST",
//       body: JSON.stringify(api_param),
//       headers: API_HEADER,
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((response) => {
//         if (response.data.loginFlag === "Y") {
//           const sessionId = generateSessionId();
//           sessionStorage.setItem("token", response.token);
//           sessionStorage.setItem("sessionId", sessionId);
//           toast.success("Login Successfully !");
//           navigate("/admin_dashboard");
//           setPageLogin(false);
//           startSessionTimer(sessionId);
//         } else if (response?.data?.invalidLoginAttem === 3) {
//           toast.error("ACCOUNT LOCKED");
//         } else {
//           toast.error("Invalid Username or Password");
//         }
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   };
//   const startSessionTimer = (sessionId) => {
//     setTimeout(() => {
//       logout(sessionId);
//       navigate("/login");
//     }, 1200000);
//   };
//   const logout = (sessionId) => {
//     sessionStorage.removeItem("session");
//     toast.info("Session Expired. Please login again.");
//     navigate("/login");
//     setPageLogin(true);
//   };
//   useEffect(() => {
//     const token = sessionStorage.getItem("session");
//     if (token) {
//       navigate("/login");
//       startSessionTimer();
//     }
//   }, [navigate]);
//   const handleClickShowPassword = () => setShowPassword((show) => !show);
//   const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };
//   return (
//     <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center bg_login">
//       <CContainer>
//         <CRow className="justify-content-center">
//           <CCol md={8} lg={5}>
//             <CCardGroup>
//               <CCard className="p-4">
//                 <CCardBody className="logn_mn1">
//                   <CForm>
//                     <h1>Login</h1>
//                     <p className="text-body-secondary">
//                       Sign-in to your account
//                     </p>
//                     <div className="fon_two">
//                       <TextField
//                         className="dissbb"
//                         id="input-with-icon-textfield"
//                         label="User ID"
//                         value={userName}
//                         onChange={(e) => setUserName(e.target.value)}
//                         endAdornment={
//                           <InputAdornment position="end">
//                             <IconButton
//                               aria-label="toggle password visibility"
//                               onClick={handleClickShowPassword}
//                               onMouseDown={handleMouseDownPassword}
//                             >
//                               {showPassword ? (
//                                 <VisibilityOff />
//                               ) : (
//                                 <Visibility />
//                               )}
//                             </IconButton>
//                           </InputAdornment>
//                         }
//                         variant="standard"
//                       />

//                       <FormControl
//                         sx={{ m: 1, width: "25ch" }}
//                         variant="standard"
//                       >
//                         <InputLabel htmlFor="standard-adornment-password">
//                           Password
//                         </InputLabel>
//                         <Input
//                           onChange={(e) => setPassword(e.target.value)}
//                           value={password}
//                           id="standard-adornment-password"
//                           type={showPassword ? "text" : "password"}
//                           endAdornment={
//                             <InputAdornment position="end">
//                               <IconButton
//                                 aria-label="toggle password visibility"
//                                 onClick={handleClickShowPassword}
//                                 onMouseDown={handleMouseDownPassword}
//                               >
//                                 {showPassword ? (
//                                   <VisibilityOff />
//                                 ) : (
//                                   <Visibility />
//                                 )}
//                               </IconButton>
//                             </InputAdornment>
//                           }
//                         />
//                       </FormControl>
//                     </div>
//                     <CRow>
//                       <CCol className="btn_top1">
//                         <CButton
//                           color="primary"
//                           className="sv_btn"
//                           onClick={handleSubmit}
//                         >
//                           Login
//                         </CButton>
//                       </CCol>
//                     </CRow>
//                   </CForm>
//                 </CCardBody>
//               </CCard>
//             </CCardGroup>
//           </CCol>
//         </CRow>
//       </CContainer>
//     </div>
//   );
// };
// export default Login;

// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import ReactLoading from "react-loading";
// import { toast } from "react-toastify";
// import TextField from "@mui/material/TextField";
// import InputAdornment from "@mui/material/InputAdornment";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import Input from "@mui/material/Input";
// import IconButton from "@mui/material/IconButton";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import {
//   CButton,
//   CCard,
//   CCardBody,
//   CCardGroup,
//   CCol,
//   CContainer,
//   CForm,
//   CRow,
// } from "@coreui/react";
// import {
//   API_HEADER,
//   UserLogin,
//   UserPinCheck,
//   UserPinResend,
// } from "../../Components/utiles/api_constant";

// const Login = ({ setPageLogin }) => {
//   const [userName, setUserName] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
  
//   const [otpSent, setOtpSent] = useState("");
//   const [resendRequest, setResendRequest] = useState("");
//   const [otpRequested, setOtpRequested] = useState(false);
//   const [otpValid, setOtpValid] = useState(false);
//   const [timeLeft, setTimeLeft] = useState(600); // 10 minutes in seconds
//   const navigate = useNavigate();
//   const [showResendOTP, setShowResendOTP] = useState(false);
// const [otp1,setOTP1]= useState("")
// const [otp2,setOTP2]= useState("")
// const [otp3,setOTP3]= useState("")
// const [otp4,setOTP4]= useState("")
// const [otp5,setOTP5]= useState("")
// const [otp6,setOTP6]= useState("")
// const [otp, setOtp] = useState([
//   otp1,otp2,otp3,otp4,otp5,otp6 
// ]);
//   const generateSessionId = () => {
//     return "xxxx-xxxx-4xxx-yxxx-xxxx".replace(/[xy]/g, function (c) {
//       var r = (Math.random() * 16) | 0,
//         v = c == "x" ? r : (r & 0x3) | 0x8;
//       return v.toString(16);
//     });
//   };
// console.log( otp1,otp2,otp3,otp4,otp5,otp6,"divyanshi otp")
//   useEffect(() => {
//     if (otpRequested && timeLeft > 0) {
//       const timer = setInterval(() => {
//         setTimeLeft((prev) => prev - 1);
//       }, 1000);
//       return () => clearInterval(timer);
//     }
//   }, [otpRequested, timeLeft]);

//   const handleSubmit = () => {
//     if (!userName) {
//       toast.error("Username is required");
//       return;
//     }
//     if (!password) {
//       toast.error("Password is required");
//       return;
//     }
//     const api_param = {
//       brandCode: "SKDMT",
//       countryCode: "IN",
//       companyId: "SKDMT",
//       userId: userName,
//       ipAddress: "1::1",
//       password: password,
//     };
//     fetch(UserLogin, {
//       method: "POST",
//       body: JSON.stringify(api_param),
//       headers: API_HEADER,
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((response) => {
//         if (response?.data?.loginFlag === "Y") {
//           setOtpSent(response?.data);
//           setOtpRequested(true);
//           toast.success("The OTP has been sent");
//         } else if (response?.data?.loginFlag === "N") {
//           toast.error(response?.data?.msg);
//         }
//         if (response?.data?.invalidLoginAttem === 3) {
//           toast.error("ACCOUNT LOCKED");
//         }
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   };
//   console.log(otpSent.requestId, "otpSent dgh");

//   const handleOtpSubmit = () => {
//     const api_param = {
//       brandCode: "SKDMT",
//       countryCode: "IN",
//       companyId: "SKDMT",
//       userId: otpSent?.userId,
//       ipAddress: "1::1",
//       requestId:  otpSent?.requestId, //resendRequest === null ? otpSent?.requestId : resendRequest?.requestId,
//       mPin: otp,
//     };
//     fetch(UserPinCheck, {
//       method: "POST",
//       body: JSON.stringify(api_param),
//       headers: API_HEADER,
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((response) => {
//         if (response.data.loginFlag === "Y") {
//           const sessionId = generateSessionId();
//           sessionStorage.setItem("token", "fake-token");
//           sessionStorage.setItem("sessionId", sessionId);
//           toast.success("Login Successfully !");
//           navigate("/admin_dashboard");
//           setPageLogin(false);
//           startSessionTimer(sessionId);
//         } else {
//           toast.error("Invalid OTP");
//         }
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   };

//   console.log(resendRequest, "resendRequest");

//   const resendOtp = () => {
//     const api_param = {
//       brandCode: "SKDMT",
//       countryCode: "IN",
//       companyId: "SKDMT",
//       userId: otpSent?.userId,
//       ipAddress: "1::1",
//       requestId: otpSent?.requestId,
//     };
//     fetch(UserPinResend, {
//       method: "POST",
//       body: JSON.stringify(api_param),
//       headers: API_HEADER,
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((response) => {
//         if (response.data.msg === "Y") {
//           toast.success("The OTP has been sent");
//           setResendRequest(response?.data?.requestId);
//         } else {
//           toast.error("Error! Please contact Admin! ");
//         }
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   };

//   const startSessionTimer = (sessionId) => {
//     setTimeout(() => {
//       logout(sessionId);
//       navigate("/login");
//     }, 1200000);
//   };
//   const logout = (sessionId) => {
//     sessionStorage.removeItem("session");
//     toast.info("Session Expired. Please login again.");
//     navigate("/login");
//     setPageLogin(true);
//   };

//   useEffect(() => {
//     const token = sessionStorage.getItem("session");
//     if (token) {
//       navigate("/login");
//       startSessionTimer();
//     }
//   }, [navigate]);

//   const handleClickShowPassword = () => setShowPassword((show) => !show);
//   const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };

//   return (
//     <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center bg_login">
//       <CContainer>
//         <CRow className="justify-content-center">
//           <CCol md={8} lg={5}>
//             <CCardGroup>
//               <CCard className="p-4">
//                 <CCardBody className="logn_mn1">
//                   <CForm>
//                     <h1>Login</h1>
//                     <p className="text-body-secondary">
//                       Sign-in to your account
//                     </p>
//                     {otpRequested ? (
//                       <>
//                         {/* <TextField
//                           label="OTP"
//                           value={otp}
//                           maxLength={6}

//                           onChange={(e) => setOtp(e.target.value)}
//                           variant="standard"
//                         /> */}


// <div className="otp-input">
// <input class="otp" type="text"   onChange={(e) => setOTP1(e.target.value)}   value={otp1} oninput="digitValidate(this)" onkeyup="tabChange(1)" maxlength="1"></input>
// <input class="otp" type="text" onChange={(e) => setOTP2(e.target.value)}   value={otp2} oninput="digitValidate(this)" onkeyup="tabChange(1)" maxlength="2"></input>
// <input class="otp" type="text" onChange={(e) => setOTP3(e.target.value)}   value={otp3} oninput="digitValidate(this)" onkeyup="tabChange(1)" maxlength="3"></input>
// <input class="otp" type="text" onChange={(e) => setOTP4(e.target.value)}   value={otp4} oninput="digitValidate(this)" onkeyup="tabChange(1)" maxlength="4"></input>
// <input class="otp" type="text" onChange={(e) => setOTP5(e.target.value)}   value={otp5} oninput="digitValidate(this)" onkeyup="tabChange(1)" maxlength="5"></input>
// <input class="otp" type="text" onChange={(e) => setOTP6(e.target.value)}   value={otp6} oninput="digitValidate(this)" onkeyup="tabChange(1)" maxlength="6"></input>
             

//           </div>


//                         <div className="timer">
//                           <p>
//                             Time Left: {Math.floor(timeLeft / 60)}:
//                             {timeLeft % 60}
//                           </p>

//                           <p
//                             className="resend-otp" 
//                             onClick={resendOtp}
//                           >
//                             Resend OTP
//                           </p>
//                         </div>
//                         <CButton
//                           color="primary"
//                           className="sv_btn"
//                           onClick={handleOtpSubmit}
//                         >
//                           Validate OTP
//                         </CButton>
//                       </>
//                     ) : (
//                       <div className="fon_two">
//                         <TextField
//                           className="dissbb"
//                           id="input-with-icon-textfield"
//                           label="User ID"
//                           value={userName}
//                           onChange={(e) => setUserName(e.target.value)}
//                           endAdornment={
//                             <InputAdornment position="end">
//                               <IconButton
//                                 aria-label="toggle password visibility"
//                                 onClick={handleClickShowPassword}
//                                 onMouseDown={handleMouseDownPassword}
//                               >
//                                 {showPassword ? (
//                                   <VisibilityOff />
//                                 ) : (
//                                   <Visibility />
//                                 )}
//                               </IconButton>
//                             </InputAdornment>
//                           }
//                           variant="standard"
//                         />

//                         <FormControl
//                           sx={{ m: 1, width: "25ch" }}
//                           variant="standard"
//                         >
//                           <InputLabel htmlFor="standard-adornment-password">
//                             Password
//                           </InputLabel>
//                           <Input
//                             onChange={(e) => setPassword(e.target.value)}
//                             value={password}
//                             id="standard-adornment-password"
//                             type={showPassword ? "text" : "password"}
//                             endAdornment={
//                               <InputAdornment position="end">
//                                 <IconButton
//                                   aria-label="toggle password visibility"
//                                   onClick={handleClickShowPassword}
//                                   onMouseDown={handleMouseDownPassword}
//                                 >
//                                   {showPassword ? (
//                                     <VisibilityOff />
//                                   ) : (
//                                     <Visibility />
//                                   )}
//                                 </IconButton>
//                               </InputAdornment>
//                             }
//                           />
//                         </FormControl>
//                       </div>
//                     )}
//                     {!otpRequested && (
//                       <CRow>
//                         <CCol className="btn_top1">
//                           <CButton
//                             color="primary"
//                             className="sv_btn"
//                             onClick={handleSubmit}
//                           >
//                             Login
//                           </CButton>
//                         </CCol>
//                       </CRow>
//                     )}
//                   </CForm>
//                 </CCardBody>
//               </CCard>
//             </CCardGroup>
//           </CCol>
//         </CRow>
//       </CContainer>
//     </div>
//   );
// };

// export default Login;






import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CRow,
} from "@coreui/react";
import {
  API_HEADER,
  UserLogin,
  UserPinCheck,
  UserPinResend,
} from "../../Components/utiles/api_constant";
import ReactLoading from "react-loading";

const Login = ({ setPageLogin }) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const [otpRequested, setOtpRequested] = useState(false);
  const [otpSent, setOtpSent] = useState("");
  const [resendRequest, setResendRequest] = useState("");
  const [timeLeft, setTimeLeft] = useState(600); // 10 minutes in seconds
  const [otp, setOtp] = useState(Array(6).fill("")); // Initializing array with 6 empty strings
  const otpRefs = useRef(Array(6).fill(null)); // Array of refs for the OTP inputs
  const navigate = useNavigate();
  const [showResendOTP, setShowResendOTP] = useState(false);
  const [timeShowOtp, setTimeShowOtp] = useState(60);
  
  useEffect(() => {
    if (otpRequested && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
        setTimeShowOtp((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [otpRequested, timeLeft]);

  const generateSessionId = () => {
    return "xxxx-xxxx-4xxx-yxxx-xxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const handleSubmit = () => {
    if (!userName) {
      toast.error("Username is required");
      return;
    }
    if (!password) {
      toast.error("Password is required");
      return;
    }
  
    const api_param = {
      brandCode: "SKDMT",
      countryCode: "IN",
      companyId: "SKDMT",
      userId: userName,
      ipAddress: "1::1",
      password: password,
    };
    setIsLoading(true);
    fetch(UserLogin, {
      method: "POST",
      body: JSON.stringify(api_param),
      headers: API_HEADER,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        if (response?.data?.loginFlag === "Y") {
          setOtpSent(response?.data);
          setOtpRequested(true);
          toast.success("The OTP has been sent");
          setIsLoading(false); 
        } else if (response?.data?.loginFlag === "N") {
          toast.error(response?.data?.msg);
        }
        if (response?.data?.invalidLoginAttem === 3) {
          toast.error("ACCOUNT LOCKED");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      ;
  };

  const handleOtpSubmit = () => {
    const combinedOtp = otp.join(""); // Combine OTP values into a single string
    const api_param = {
      brandCode: "SKDMT",
      countryCode: "IN",
      companyId: "SKDMT",
      userId: otpSent?.userId,
      ipAddress: "1::1",
      requestId: otpSent?.requestId, 
      mPin: combinedOtp,
    };
    fetch(UserPinCheck, {
      method: "POST",
      body: JSON.stringify(api_param),
      headers: API_HEADER,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        if (response.data.loginFlag === "Y") {
          const sessionId = generateSessionId();
          sessionStorage.setItem("token", "fake-token");
          sessionStorage.setItem("sessionId", sessionId);
          toast.success("Login Successfully !");
          navigate("/admin_dashboard");
          setPageLogin(false);
          startSessionTimer(sessionId);
        } else {
          toast.error("Invalid OTP");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const resendOtp = () => {
    const api_param = {
      brandCode: "SKDMT",
      countryCode: "IN",
      companyId: "SKDMT",
      userId: otpSent?.userId,
      ipAddress: "1::1",
      requestId: otpSent?.requestId,
    };
    fetch(UserPinResend, {
      method: "POST",
      body: JSON.stringify(api_param),
      headers: API_HEADER,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        if (response.data.msg === "Y") {
          toast.success("The OTP has been sent");
          setResendRequest(response?.data?.requestId);
        } else {
          toast.error("Error! Please contact Admin!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const startSessionTimer = (sessionId) => {
    setTimeout(() => {
      logout(sessionId);
      navigate("/login");
    }, 1200000);
  };

  const logout = (sessionId) => {
    sessionStorage.removeItem("session");
    toast.info("Session Expired. Please login again.");
    navigate("/login");
    setPageLogin(true);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("session");
    if (token) {
      navigate("/login");
      startSessionTimer();
    }
  }, [navigate]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeOtp = (index, value) => {
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Focus the next input if the value is not empty and there is a next input
      if (value !== "" && index < otpRefs.current.length - 1) {
        otpRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && otp[index] === "" && index > 0) {
      otpRefs.current[index - 1].focus();
    }
  };

  useEffect(() => {
    if (timeShowOtp === 0) {
      setShowResendOTP(true);
    }
  }, [timeShowOtp]);

  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center bg_login">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8} lg={5}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody className="logn_mn1">
                  <CForm>
                    <h1>Login</h1>
                    <p className="text-body-secondary">Sign-in to your account</p>

                    {isLoading && (
                    <div className="loading-container">
                      <ReactLoading
                        type="spin"
                        color="#9f0101 "
                        height={100}
                        width={100}
                      />
                    </div>
                  )}
                    {otpRequested ? (
                      <>
                        <div className="otp-input">
                          {otp.map((value, index) => (
                            <input
                              key={index}
                              ref={(el) => (otpRefs.current[index] = el)}
                              className="otp"
                              type="text"
                              value={value}
                              onChange={(e) => handleChangeOtp(index, e.target.value)}
                              onKeyDown={(e) => handleKeyDown(index, e)}
                              maxLength="1"
                            />
                          ))}
                        </div>
                        <div className="timer">
                          <p>Time Left: {Math.floor(timeLeft / 60)}:{timeLeft % 60}</p>


                          {showResendOTP && (
                            <p className="resend-otp" onClick={resendOtp}>
                              Resend OTP
                            </p>
                          )}
                        </div>
                        <CButton
                          color="primary"
                          className="sv_btn"
                          onClick={handleOtpSubmit}
                        >
                          Submit OTP
                        </CButton>
                      </>
                    ) : (
                      <div>
                        <div className="fon_two">
                          <TextField
                          className="dissbb"
                            id="input-with-icon-textfield"
                            label="User ID"
                            variant="standard"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            endAdornment={
                                                          <InputAdornment position="end">
                                                            <IconButton
                                                              aria-label="toggle password visibility"
                                                              onClick={handleClickShowPassword}
                                                              onMouseDown={handleMouseDownPassword}
                                                            >
                                                              {showPassword ? (
                                                                <VisibilityOff />
                                                              ) : (
                                                                <Visibility />
                                                              )}
                                                            </IconButton>
                                                          </InputAdornment>
                                                        }
                          />
                        </div>
                        <FormControl 
                         sx={{ m: 1, width: "25ch" }}
                          variant="standard">
                          <InputLabel htmlFor="standard-adornment-password">
                            Password
                          </InputLabel>
                          <Input
                            id="standard-adornment-password"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <CButton
                          color="primary"
                          className="sv_btn"
                          onClick={handleSubmit}
                        >
                          Sign In
                        </CButton>
                      </div>
                    )}
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;


