import React from "react";
import PaginationTop from "../../PaginationTop";
import air from "../Image/air.png";
import trn from "../Image/trn.png";
import bus from "../Image/bus.png";
import rule_hindi from "../Image/rule_hindi.jpg";
import rule_hindi_2 from "../Image/rule_hindi_2.jpg";
import rule_eng from "../Image/rule_eng.jpg";
import { Element } from "react-scroll";

import { useTranslation } from "react-i18next";
import FaqSection from "./FaqSection";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div>
      <PaginationTop />
      <div className="karauli_maharaj_mn">
        <div className="container">
          <h2>{t("header.nav6")}</h2>
          <hr className="line"></hr>
        </div>
      </div>
      <div className="information_mn">
        <div className="container">
          <h2>{t("update.desc25")}</h2>

          <p>{t("update.desc26")}</p>

          <h2> {t("update.desc28")}</h2>

          <p>{t("update.desc29")}</p>
        </div>
      </div>
      {/* rule and regulation  */}
      <div class="reach_mn">
        <h2>{t("MISCINFO.rule_regulation")}</h2>
        <hr class="line3"></hr>
        <div className="container">
          <div class="row">
            <div class="col-md-4 col-sm-6 col-xs-12">
              <div className="conatact-single-box2">
                <div>
                  <img className="ruleImg" src={rule_hindi} />
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="conatact-single-box2">
                {" "}
                <div>
                  <img className="ruleImg" src={rule_eng} />
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6 col-xs-12">
              <div class="conatact-single-box2">
                {" "}
                <div>
                  <img className="ruleImg" src={rule_hindi_2} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact_mn2">
        <div className="container">
          <div class="contacts-section">
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-lg-8 col-md-7">
                <div class="conatact-single-box">
                  <div class="contacts-icon">
                    <span class="fa fa-home"></span>
                  </div>
                  <div class="contact-title">
                    <h5> {t("MISCINFO.add1")}</h5>
                  </div>
                  <div class="contact-description">
                    <p>
                      <p>{t("MISCINFO.add1_desc")}</p>
                    </p>
                  </div>
                </div>
              </div>
              {/* <div class="col-lg-6 col-md-6">
                <div class="conatact-single-box">
                  <div class="contacts-icon">
                    <span class="fa fa-envelope"></span>
                  </div>
                  <div class="contact-title">
                    <h5> {t("MISCINFO.email_add")} </h5>
                  </div>
                  <div class="contact-description">
                    <p>{t("MISCINFO.email_desc")}</p>
                  </div>
                </div>
              </div> */}

              <div class="reach_mn">
                <h2>{t("MISCINFO.reach")}</h2>
                <hr class="line3"></hr>

                <div class="row">
                  <div class="col-md-4 col-sm-6 col-xs-12">
                    <div className="conatact-single-box2">
                      <div className="contacts-icon">
                        {" "}
                        <img className="" src={air} />
                      </div>
                      <div className="contact-title">
                        <h5> {t("MISCINFO.rearch1")} </h5>
                      </div>
                      <div className="contact-description">
                        <p>{t("MISCINFO.reach1_desc")}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="conatact-single-box2">
                      <div className="contacts-icon">
                        {" "}
                        <img className="" src={trn} />
                      </div>
                      <div class="contact-title">
                        <h5> {t("MISCINFO.rearch2")} </h5>
                      </div>

                      <div class="contact-description">
                        <p>{t("MISCINFO.reach2_desc")}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="conatact-single-box2">
                      <div className="contacts-icon">
                        {" "}
                        <img className="" src={bus} />
                      </div>
                      <div class="contact-title">
                        <h5> {t("MISCINFO.rearch3")}</h5>
                      </div>
                      <div class="contact-description">
                        <p>
                          {t("MISCINFO.reach3_desc")}
                          <br></br>
                          {t("MISCINFO.reach3_desc1")}
                          <br></br>
                          {t("MISCINFO.reach3_desc2")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="map_mn">
            <iframe
              className="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1787770.0545796487!2d77.01034521044551!3d28.935816038213318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a0a3ab1b8ec5d9%3A0xea7786fa26ed7dd0!2sKainchi%20Dham%20-%20Shri%20Neem%20Karoli%20Baba%20Ashram!5e0!3m2!1sen!2sin!4v1717755238652!5m2!1sen!2sin"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <Element name="faqs" className="element">
          <div class="reach_mn">
          <h2>Frequently Asked Questions </h2>
          <hr class="line3"></hr>


          <div className="faq_section">
          <FaqSection />
          </div>
           
          </div>
         
        </Element>

          
          
        </div>
      </div>
    </div>
  );
};

export default Contact;
