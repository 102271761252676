// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;




// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
 
// import translationEN from "./locales/en.json";
// import translationHN from "./locales/hi.json";
 
// const resources = {
//   en: {
//     translation: translationEN,
//   },
//   hn: {
//     translation: translationHN,
//   },
// };
 
// i18n.use(initReactI18next).init({
//   resources,
//   lng: "en",
//   fallbackLng: "en",
 
//   interpolation: {
//     escapeValue: false,
//   },
// });
 
// export default i18n;
 