import React, { useState } from "react";
import ImageUploadForm from "./ImageUploadForm";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import PaginationTop from "../../PaginationTop";
import g1 from "../Image/g1.jpg";
import g2 from "../Image/g2.jpg";
import g3 from "../Image/g3.jpg";
import g4 from "../Image/g4.jpg";
import g5 from "../Image/g5.jpg";
import g6 from "../Image/g6.jpg";
import g7 from "../Image/g7.jpg";
import g8 from "../Image/g8.jpg";
import g9 from "../Image/g9.jpg";
import g10 from "../Image/g10.jpg";
import g11 from "../Image/g11.jpg";
import g12 from "../Image/g12.jpg";
import g13 from "../Image/g13.jpg";
import g14 from "../Image/g14.jpg";
import { useTranslation } from "react-i18next";
import img1 from "../Image/Kainchi/Kainchi/img1.jpg";
import img2 from "../Image/Kainchi/Kainchi/img2.jpg";
import img3 from "../Image/Kainchi/Kainchi/img3.jpg";
import img4 from "../Image/Kainchi/Kainchi/img4.jpg";
import img5 from "../Image/Kainchi/Kainchi/img5.jpg";
import img6 from "../Image/Kainchi/Kainchi/img6.jpg";
import img7 from "../Image/Kainchi/Kainchi/img7.jpg";
import mj7 from "../Image/Maharaj/Maharaj/mj7.jpg";

import mh1 from "../Image/Maharaj/Maharaj/mh1.jpg";
import mh2 from "../Image/Maharaj/Maharaj/mh2.jpg";
import mj3 from "../Image/Maharaj/Maharaj/mj3.jpg";
import mh4 from "../Image/Maharaj/Maharaj/mh4.jpg";
import mj5 from "../Image/Maharaj/Maharaj/mj5.jpg";
import mj6 from "../Image/Maharaj/Maharaj/mj6.jpg";
import mh8 from "../Image/Maharaj/Maharaj/mh8.jpg";
import sidhi from "../Image/Siddhi/Siddhi/sidhi.jpg";
import CustomerSlider from "./ImageSliderComponents/CustomerSlider";
import images from "./images";

const Sidhimaa = () => {
    const { t } = useTranslation();
  return (
    <div>
       <PaginationTop />
      <div className="karauli_maharaj_mn">
        <div className="container">
          <h2>{t("header.nav4")}</h2>
          <hr className="line"></hr>
        </div>
      </div>
      <div className="text-center">
        <div className="container">
          <h2>{t("header.nav2")}</h2>
          <hr className="line"></hr>
        </div>
      </div>
      <div className="gallery_mn_top">
        <div className="container">
          <div className="gallery_mn">
            <div className="row">
              <div class="col-md-4 col-sm-6 col-xs-12">
                <img className="" src={g9} />
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12">
                <img className="" src={g10} />
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12">
                <img className="" src={g11} />
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12">
                <img className="" src={sidhi} style={{height: "334px"}}  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidhimaa
