import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import img_enq from "./Components/Image/Group 9975.png";
import { SaveComment, API_HEADER } from "./Components/utiles/api_constant";
import { toast } from "react-toastify";
import { animateScroll as scroll, scroller } from "react-scroll";

const EnquiryForm = () => {
  const navigate = useNavigate();
  const [scrollTarget, setScrollTarget] = useState(null);
  const [lgShow, setLgShow] = useState(false);
  const api_url = SaveComment;
  const api_header = API_HEADER;
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    number: "",
    name: "",
    comment: "",
  });
  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setErrors((prev) => ({ ...prev, email: "Invalid email format" }));
    } else {
      setErrors((prev) => ({ ...prev, email: "" }));
    }
  };
  const handleNumberChange = (e) => {
    const { value } = e.target;
    const numberRegex = /^[0-9]*$/;

    if (numberRegex.test(value)) {
      setNumber(value);
      if (value.length !== 10) {
        setErrors((prev) => ({
          ...prev,
          number: "Number must be 10 digits long",
        }));
      } else {
        setErrors((prev) => ({ ...prev, number: "" }));
      }
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ comment, name, email, number, errors });
    if (
      !errors.email &&
      !errors.number &&
      !errors.comment &&
      !errors.name &&
      email &&
      number &&
      comment &&
      name
    ) {
      // Submit the form
      var api_param = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        name: name,
        comment: comment,
        mobile: number,
        email: email,
        city: "bbk",
        calledBy: "ALL", // ALL,EDIT,WEB
        commentUniqueSerial: 0,
        status: "A",
        Action: "A",
      };

      fetch(api_url, {
        method: "POST",
        body: JSON.stringify(api_param),
        headers: api_header,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((response) => {
          console.log("Success:", response);
          toast.success("Form submitted");
          setLgShow(false);
          setComment("");
          setName("");
          setEmail("");
          setNumber("");
          setErrors({ email: "", number: "", name: "", comment: "" });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      toast.error("Please fill in all required fields");
    }
  };

  useEffect(() => {
    if (scrollTarget) {
      scroller.scrollTo(scrollTarget, {
        duration: 300,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: offsetMap[scrollTarget] || 0,
      });
      setScrollTarget(null);
    }
  }, [scrollTarget]);

  const offsetMap = {
    faqs: 1200,
  };

  const handleImageClick = (value) => {
    setScrollTarget(value);
    navigate("/contact");
  };

  return (
    <div>
      <div className="top-to-btm">
        {" "}
        <p
          className="icon-position1 icon-style22"
          onClick={() => {
            handleImageClick("faqs");
          }}
        >
          {/* Enquiry  */}
          <img src={img_enq} alt="enq_img" />
        </p>
        <Modal
          size="lg modl_wd"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="contact_mn1">
              <div className="container">
                <div className="form_mn">
                  <h3>Leave a Message</h3>
                  <div class="col-md-12">
                    <div class="form-group ">
                      <textarea
                        name="message"
                        id="message"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        class="form-control fom_mes"
                        placeholder="Your Message *"
                      ></textarea>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4 col-sm-6 col-xs-12">
                      <div class="form-group ">
                        <input
                          type="text"
                          name="name"
                          class="form-control fom_stl"
                          id="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Full Name"
                        ></input>
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-6 col-xs-12">
                      <div class="form-group ">
                        <input
                          type="email"
                          name="name"
                          class="form-control fom_stl"
                          id="EMAIL"
                          value={email}
                          onChange={handleEmailChange}
                          placeholder="Email ID"
                          required
                        ></input>
                        {/* {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>} */}
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12">
                      <div class="form-group ">
                        <input
                          type="text"
                          name="name"
                          class="form-control fom_stl"
                          id="number"
                          value={number}
                          placeholder="Phone"
                          onChange={handleNumberChange}
                          maxLength={10}
                          required
                        ></input>
                        {/* {errors.number && <p style={{ color: 'red' }}>{errors.number}</p>} */}
                      </div>
                    </div>

                    <button
                      type="button"
                      onClick={handleSubmit}
                      class="post_btn"
                    >
                      <span>Submit</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default EnquiryForm;
