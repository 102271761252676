import React, { useEffect, useState } from "react";
import PaginationTop from "../../PaginationTop";
import { useTranslation } from "react-i18next";
import { API_HEADER, GetPageData } from "../utiles/api_constant";
import Latest from "../Image/Latest.png";
import new_image from "../Image/new.png";

const EventForm = () => {
  const { t, i18n } = useTranslation();
  const api_url = GetPageData;
  const api_header = API_HEADER;
  const [updateListApi, setUpdateList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const url = api_url;
      const headers = api_header;
      const data = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        pageUniqueSerial: 0,
        pageCode: "UPDATES",
        calledBy: "ALL",
        pageDataHindi: "",
      };
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const responseData = await response.json();
          const respo = responseData?.data;
          setUpdateList(respo?.pageDataList);
        } else {
          throw new Error(
            `Request failed with status code: ${response.status}`()
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      <PaginationTop />
      <div className="karauli_maharaj_mn">
        <div className="container">
          <h2>{t("update.subtitle")}</h2>
          <hr className="line"></hr>
        </div>
      </div>

      <div className="information_mn">
      <div className="container box2">
          {/* <h1>{t("update.title1")} </h1>

          <hr className="line3"></hr> */}
<img src={new_image} alt="new_imag" className="new_imag"/>
         
          <img src={Latest} className="latest_img" alt="test"/>

        </div>
        <div className="container box2">
          <h1>{t("update.subtitle")} </h1>

          <hr className="line3"></hr>

          <h4>{t("update.desc1")} </h4>

          <p> </p>
          {updateListApi
            ?.filter((itemList) => itemList.status === "A")
            .map((itemList) => (
              <p className="no_padding">
                <span class="cir_sml">
                  <i class="fa fa-fw"></i>
                </span>

                {i18n.language === "hi"
                  ? itemList.pageDataHindi
                  : itemList.pageData}
              </p>
            ))}

          {/* <p className="no_padding">
            <span class="cir_sml">
              <i class="fa fa-fw"></i>
            </span>
            {t("update.desc9")}-
          </p>

          <p className="no_padding">
            <span class="cir_sml">
              <i class="fa fa-fw"></i>
            </span>
            {t("update.desc10")}
          </p>

          <p className="no_padding">
            <span class="cir_sml">
              <i class="fa fa-fw"></i>
            </span>
            {t("update.desc13")}
          </p>

          <p className="no_padding">
            <span class="cir_sml">
              <i class="fa fa-fw"></i>
            </span>
            {t("update.desc15")}{" "}
          </p>

          <p className="no_padding">
            <span class="cir_sml">
              <i class="fa fa-fw"></i>
            </span>
            {t("update.desc16")}
          </p>
          <p className="no_padding">
            <span class="cir_sml">
              <i class="fa fa-fw"></i>
            </span>
            {t("update.desc30")}
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default EventForm;
