import React from "react";
import logoImg from "../Image/logo.png";
import fImg from "../Image/f.png";
import yoImg from "../Image/instra.png";
import twImg from "../Image/tw.png";
import yo from "../Image/yo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="footer_mn">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-12 footer_logo">
              <img src={logoImg} />
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12 footer_tx">
              <h3>{t("footer.title")}</h3>
              <hr className="line2"></hr>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12 footer_tx2">
              <h3>{t("footer.desc1")}</h3>
              <hr className="line2"></hr>
              <ul>
                <li>
                  <Link to="/maharaj_ji">{t("header.nav1")}</Link>
                </li>
                <li>
                  <Link to="/siddhi_maa">{t("header.nav2")}</Link>
                </li>
                <li>
                  <Link to="/about">{t("header.nav3")}</Link>
                </li>
                <li>
                  <Link to="gallery">{t("header.nav4")}</Link>
                </li>
                <li>
                  <Link to="/events">{t("header.nav5")}</Link>
                </li>
                <li>
                  <Link to="/contact">{t("header.nav6")}</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12 footer_tx">
              <h3>{t("MISCINFO.title")}</h3>
              <hr className="line2"></hr>
              <h4>{t("MISCINFO.add1")}</h4>
              <p>
                <p>
                  <span class="fa fa-home"></span>
                  {""} {t("MISCINFO.add1_desc")}
                </p>
              </p>
              {/* <h4>{t("footer.email")}</h4> */}
              <p>
                <span class="fa fa-envelope"></span>{" "}
                inquiry@shreekainchimandir.org
              </p>
              <ul>
                <li>
                  <a
                    title="Facebook"
                    href="https://www.facebook.com/people/Shree-Kainchi-Mandir-Trust/61559096415522/?mibextid=LQQJ4d"
                    target="_blank"
                  >
                    <img src={fImg} />
                  </a>
                </li>

                <li>
                  <a
                    title="Facebook"
                    href="https://www.instagram.com/shreekainchimandirtrust/?igsh=MXgwMWpjODJ3eTc3bA%3D%3D"
                    target="_blank"
                  >
                    <img src={yoImg} />
                  </a>
                </li>

                <li>
                  <a
                    title="Facebook"
                    href="https://www.youtube.com/@SHREEKAINCHIMANDIRTRUST"
                    target="_blank"
                  >
                    <img src={yo} />
                  </a>
                </li>

                <li>
                  <a
                    title="Facebook"
                    href="https://X.com/kainchimandir"
                    target="_blank"
                  >
                    <img src={twImg} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="coprt_mn">
        <p className="best_view">Best Viewed : Google Chrome(126.0.6478.126/127)</p>
      </div>
    </div>
  );
};
export default Footer;
