import React, { useState, useEffect } from "react";
import "./style.css";

function CustomerSlider({ children, activeIndex: initialIndex = 0 }) {
  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const [slideDone, setSlideDone] = useState(true);
  const [timeID, setTimeID] = useState(null);

  useEffect(() => {
    if (slideDone) {
      setSlideDone(false);
      const id = setTimeout(() => {
        slideNext();
        setSlideDone(true);
      }, 5000);
      setTimeID(id);
      return () => clearTimeout(id);
    }
  }, [slideDone, activeIndex]);

  useEffect(() => {
    setActiveIndex(initialIndex);
  }, [initialIndex]);

  const slideNext = () => {
    setActiveIndex((val) => (val >= children.length - 1 ? 0 : val + 1));
  };

  const slidePrev = () => {
    setActiveIndex((val) => (val <= 0 ? children.length - 1 : val - 1));
  };

  const AutoPlayStop = () => {
    if (timeID) {
      clearTimeout(timeID);
      setSlideDone(false);
    }
  };

  const AutoPlayStart = () => {
    if (!slideDone) {
      setSlideDone(true);
    }
  };

  return (
    <div
      className="container__slider"
      onMouseEnter={AutoPlayStop}
      onMouseLeave={AutoPlayStart}
      style={{ "--active-index": activeIndex }}
    >
      {React.Children.map(children, (child, index) => (
        <div
          className={`slider__item ${activeIndex === index ? "slider__item-active" : ""}`}
          key={index}
        >
          {child}
        </div>
      ))}

      <div className="container__slider__links">
        {React.Children.map(children, (child, index) => (
          <button
            key={index}
            className={
              activeIndex === index
                ? "container__slider__links-small container__slider__links-small-active"
                : "container__slider__links-small"
            }
            onClick={(e) => {
              e.preventDefault();
              setActiveIndex(index);
            }}
          ></button>
        ))}
      </div>

      <button
        className="slider__btn-next"
        onClick={(e) => {
          e.preventDefault();
          slideNext();
        }}
      >
        {">"}
      </button>
      <button
        className="slider__btn-prev"
        onClick={(e) => {
          e.preventDefault();
          slidePrev();
        }}
      >
        {"<"}
      </button>
    </div>
  );
}

export default CustomerSlider;
