export const LOG_IN_BASE_URL =
  "https://g2.orbitsys.com/OrbitsysIdentityApi/Api";

const businessOwnerCode = "ORBITSYS";

export const LOGIN_HEADER = {
  // Accept: "application/json",
  // "Content-Type": "application/json",
  countryCode: "IN",
  BusinessOwnerCode: businessOwnerCode,
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  "Client-Ip": "127.0.0.1",
};
export const BASE_URL = "https://orbitsys-api-managementservice.azure-api.net/skdmt/api/skdmt"
//"https://orbitsys-demo-apimanagementservice.azure-api.net/skdmt/api/skdmt"
//export const BASE_URL = "https://mobile.orbitsys.com/skdmt/api/skdmt"
export const API_HEADER = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  "api-key": "04577BA6-3E32-456C-B528-E41E20D28D79",
  
};
export const submitCredential = `${LOG_IN_BASE_URL}/Identity/AppAuthenticate`;
export const SaveComment = `${BASE_URL}/SaveCommentData`;
export const EventList = `${BASE_URL}/GetEventList`;
export const SaveEvent = `${BASE_URL}/EventSave`;
export const UploadDocument = `${BASE_URL}/UploadDocument`;
export const DeleteDocument = `${BASE_URL}/DeleteDocument`;
export const CommentList = `${BASE_URL}/CommentList`;

export const SavePageData = `${BASE_URL}/SavePageData`;
export const GetVisitorCount = `${BASE_URL}/GetVisitorCount`;
export const SaveVisitorData = `${BASE_URL}/SaveVisitorData`;
export const GetPageData = `${BASE_URL}/GetPageData`;
export const GetGalleryImageList = `${BASE_URL}/GetGalleryImageList`;
export const DocumentHideShow = `${BASE_URL}/DocumentHideShow`;

export const SaveFaqData = `${BASE_URL}/SaveFaqData`;

export const UserPinResend = `${BASE_URL}/UserPinResend`;
export const UserPinCheck = `${BASE_URL}/UserPinCheck`;
export const GetFAQData = `${BASE_URL}/GetFAQData`;

export const UserLogin = `${BASE_URL}/UserLogin`;
