import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import {
  cilTrash,
  cibStatuspage,
  cilLowVision,
  cilPencil,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { toast } from "react-toastify";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { CCard, CCardHeader, CCol, CForm, CFormLabel } from "@coreui/react";
import {
  API_HEADER,
  GetFAQData,
  SaveFaqData,
} from "../../../../../Components/utiles/api_constant";
import { Chip } from "@mui/material";
const Faq = ({ showSidebar }) => {
  const [formShow, setFormShow] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [question, setQuestion] = useState("");
  const [question1, setQuestion1] = useState("");
  const [refreshEventList, setRefereshList] = useState(false);
  const [answer, setAnswer] = useState("");
  const [answer1, setAnswer1] = useState("");
  const api_url = GetFAQData;
  const api_url1 = SaveFaqData;
  const [isLoading, setIsLoading] = useState(true);
  const api_header = API_HEADER;
  const [selectRow, setSelectRow] = useState("");
  const handleFormShow = () => {
    setFormShow(true);
  };
  useEffect(() => {
    setQuestion(selectRow?.dataQuesEng);
    setAnswer(selectRow?.dataAnsEng);
    setQuestion1(selectRow?.dataQuesHindi);
    setAnswer1(selectRow?.dataAnsHindi);
  }, [selectRow]);
  const columns = [
    {
      headerName: "Sr.",
      field: "faqUniqueSerial",
      width: 50,
      renderHeader: () => (
        <div style={{ fontWeight: "bold" }}>
          <span>Sr.</span>
        </div>
      ),
    },
    {
      headerName: "Question English",
      field: "dataQuesEng",
      width: 200,
      renderHeader: () => (
        <div style={{ fontWeight: "bold" }}>
          <span>Question English</span>
        </div>
      ),
      renderCell: (params) => (
        <div dangerouslySetInnerHTML={{ __html: params.value }} />
      ),
    },

    {
      headerName: "Question Hindi",
      field: "dataQuesHindi",
      width: 200,
      renderHeader: () => (
        <div style={{ fontWeight: "bold" }}>
          <span>Question Hindi</span>
        </div>
      ),
      renderCell: (params) => (
        <div>{params.value}</div> // Plain text for Hindi questions
      ),
    },
    {
      headerName: "Answer English",
      field: "dataAnsEng",
      width: 200,
      renderHeader: () => (
        <div style={{ fontWeight: "bold" }}>
          <span>Answer English</span>
        </div>
      ),
      renderCell: (params) => (
        <div dangerouslySetInnerHTML={{ __html: params.value }} />
      ),
    },
    {
      field: "dataAnsHindi",
      headerName: "Answer Hindi",
      width: 200,
      renderHeader: () => (
        <div style={{ fontWeight: "bold" }}>
          <span>Answer Hindi</span>
        </div>
      ),
      renderCell: (params) => (
        <div>{params.value}</div> // Plain text for Hindi answers
      ),
    },

    {
      headerName: "Date/Time",
      field: "logCreateDatetime",
      width: 180,
      renderHeader: () => (
        <div style={{ fontWeight: "bold" }}>
          <span>Date/Time</span>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Hide/Show",
      width: 100,

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}
        >
          <span style={{ marginLeft: 8 }}>Hide/Show</span>
        </div>
      ),
      renderCell: (params) =>
        params.row.status === "A" ? (
          <>
            <Chip label="Hide" color="success" size="small" />
          </>
        ) : (
          <Chip label="Show" size="small" />
        ),
    },
    {
      headerName: "Action",
      field: "Action",
      type: "actions",
      width: 80,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}
        >
          <span style={{ marginLeft: 8 }}>Action</span>
        </div>
      ),
      getActions: (params) => [
        <GridActionsCellItem
          icon={<CIcon icon={cilPencil} style={{ width: "20px" }} />}
          label="Edit"
          onClick={() => {
            let row = params.row;
            editEvent(row?.faqUniqueSerial);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<CIcon icon={cibStatuspage} style={{ width: "20px" }} />}
          label=" Show"
          onClick={() => handleEventStatusChange(params.row, "A")}
          showInMenu
        />,

        <GridActionsCellItem
          icon={<CIcon icon={cilLowVision} style={{ width: "20px" }} />}
          label=" Hide"
          onClick={() => handleEventStatusChange(params.row, "H")}
          showInMenu
        />,

        <GridActionsCellItem
          label="Delete "
          icon={<CIcon icon={cilTrash} style={{ width: "20px" }} />}
          onClick={() => handleDeleteEvent(params.row)}
          showInMenu
        />,
      ],
    },
  ];
  const editEvent = (faqUniqueSerial) => {
    const rowSelection = eventList?.find(
      (item) => item.faqUniqueSerial === faqUniqueSerial
    );
    setSelectRow(rowSelection);
    setFormShow(true);
  };
  // list  api
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = api_url;
      const headers = api_header;
      const data = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        fAQUniqueSerial: 0,
        calledBy: "ALL",
      };
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const responseData = await response.json();
          const respo = responseData?.data;
          const questionList = respo?.questionList;
          setEventList(questionList);
        } else {
          throw new Error(
            `Request failed with status code: ${response.status}`
          );
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [refreshEventList]);
  // approve api
  const handleEventStatusChange = async (eventData, eventStatus) => {
    try {
      const api_param = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        faqUniqueSerial: eventData?.faqUniqueSerial,
        parentId: 1,
        status: eventStatus,
        dataQEng: eventData?.dataQuesEng,
        dataQHindi: eventData?.dataQuesHindi,
        dataAEng: eventData?.dataAnsEng,
        dataAHindi: eventData?.dataAnsHindi,
        action: "M",
      };
      const response = await fetch(api_url1, {
        method: "POST",
        body: JSON.stringify(api_param),
        headers: api_header,
      });
      if (!response.ok) throw new Error("Network response was not ok");
      await response.json();
      toast.success("Status Updated !");
      setRefereshList(!refreshEventList);
    } catch (error) {
      toast.error(error.message);
    }
  };
  // saving api
  const handleUpdateSubmit = (actionType) => {
    if (!question) {
      toast.error("Please fill both the Text Fields");
      return;
    }

    if (!question1) {
      toast.error("Please fill both the Text Fields");
      return;
    }
    if (!answer) {
      toast.error("Please fill both the Text Fields");
      return;
    }

    if (!answer1) {
      toast.error("Please fill both the Text Fields");
      return;
    }

    var api_param = {
      brandCode: "SKDMT",
      countryCode: "IN",
      companyId: "SKDMT",
      userId: "VINOD",
      ipAddress: "1::1",
      faqUniqueSerial: selectRow === null ? 0 : selectRow?.faqUniqueSerial,
      parentId: 1,
      status: "A",
      dataQEng: question,
      dataQHindi: question1,
      dataAEng: answer,
      dataAHindi: answer1,
      Action: actionType,
    };

    fetch(api_url1, {
      method: "POST",
      body: JSON.stringify(api_param),
      headers: api_header,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        toast.success("SuccessFull !!");
        handleFormHide();
        setAnswer("");
        setAnswer1("");
        setQuestion("");
        setQuestion1("");
        setRefereshList(!refreshEventList);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleFormHide = () => {
    setFormShow(false);
    setQuestion("");
    setAnswer("");
    setQuestion1("");
    setAnswer1("");
  };
  // delete data
  const handleDeleteEvent = (eventData) => {
    var api_param = {
      brandCode: "SKDMT",
      countryCode: "IN",
      companyId: "SKDMT",
      userId: "VINOD",
      ipAddress: "1::1",
      faqUniqueSerial: eventData?.faqUniqueSerial,
      parentId: 1,
      status: "A",
      dataQEng: eventData?.dataQuesEng,
      dataQHindi: eventData?.dataQuesHindi,
      dataAEng: eventData?.dataAnsEng,
      dataAHindi: eventData?.dataAnsHindi,
      Action: "D",
    };

    fetch(api_url1, {
      method: "POST",
      body: JSON.stringify(api_param),
      headers: api_header,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        toast.success("Deleted SuccessFully !");
        setRefereshList(!refreshEventList);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <div onClick={showSidebar}>
      <CCol xs={12}>
        <CCard className="mb-4" style={{ margin: "20px" }}>
          <CCardHeader>
            <div className="btn_tx">
              <>
                {" "}
                <p>FAQs</p>
                {formShow === true ? (
                  ""
                ) : (
                  <button
                    className="btn btn-danger add_event_btn"
                    onClick={handleFormShow}
                  >
                    Add New
                  </button>
                )}
              </>
            </div>
          </CCardHeader>
          <div>
            {formShow === true ? (
              <>
                <CForm>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="p-3">
                        <CFormLabel htmlFor="exampleFormControlTextarea1">
                          {" "}
                          Question English
                        </CFormLabel>
                        <textarea
                          className="form-control fom_ht"
                          value={question}
                          onChange={(e) => setQuestion(e.target.value)}
                          type="text"
                          id="exampleFormControlInput1"
                        />
                      </div>

                      <div className="p-3">
                        <CFormLabel htmlFor="exampleFormControlTextarea1">
                          {" "}
                          Question Hindi
                        </CFormLabel>
                        <textarea
                          className="form-control fom_ht"
                          value={question1}
                          onChange={(e) => setQuestion1(e.target.value)}
                          type="text"
                          id="exampleFormControlInput1"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="p-3">
                        <CFormLabel htmlFor="exampleFormControlTextarea1">
                          {" "}
                          Answer English
                        </CFormLabel>
                        <textarea
                          className="form-control fom_ht"
                          value={answer}
                          onChange={(e) => setAnswer(e.target.value)}
                          type="text"
                          id="exampleFormControlInput1"
                        />
                      </div>

                      <div className="p-3">
                        <CFormLabel htmlFor="exampleFormControlTextarea1">
                          {" "}
                          Answer Hindi
                        </CFormLabel>
                        <textarea
                          className="form-control fom_ht"
                          value={answer1}
                          onChange={(e) => setAnswer1(e.target.value)}
                          type="text"
                          id="exampleFormControlInput1"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="btn_form_mn">
                    <button
                      className="sv_btn"
                      onClick={() => {
                        selectRow.length === 0
                          ? handleUpdateSubmit("A")
                          : handleUpdateSubmit("M");
                      }}
                    >
                      Save
                    </button>

                    <button className="cn_btn" onClick={handleFormHide}>
                      Cancel
                    </button>
                  </div>
                </CForm>
              </>
            ) : (
              <>
                <div style={{ height: 450, width: "100%" }}>
                  {isLoading && (
                    <div className="loading-container">
                      <ReactLoading
                        type="spin"
                        color="#9f0101 "
                        height={100}
                        width={100}
                      />
                    </div>
                  )}
                  <DataGrid
                    rows={eventList}
                    rowSelection={false}
                    getRowId={(r) => r.faqUniqueSerial}
                    columns={columns}
                    pageSize={true}
                    pageSizeOptions={false}
                  />
                </div>
              </>
            )}
          </div>
        </CCard>
      </CCol>
    </div>
  );
};
export default Faq;
