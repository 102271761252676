import React from "react";
import tm1 from "../Image/tm1.jpg";
import tm2 from "../Image/tm2.jpg";
import tm3 from "../Image/tm3.jpg";
import tm4 from "../Image/tm4.jpg";
import PaginationTop from "../../PaginationTop";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <div>
      <PaginationTop />

      <div className="karauli_maharaj_mn">
        <div className="container">
          <h2>{t("about.title")}</h2>
          <hr className="line"></hr>
        </div>
      </div>

      <div className="about_mn">
        <div className="container">
          <div className="about_section">
            <div className="row">
              <div className="col-md-5 col-sm-6 col-xs-12">
                <div className="about_pic">
                  <img src={tm1} />
                </div>
              </div>

              <div className="col-md-7 col-sm-6 col-xs-12">
                <div className="about_tx">
                  <h3>{t("about.title")}</h3>
                  <p>{t("about.description1")}</p>
                </div>
              </div>
              <div className="about_tx">
                <br></br>
                <p>
                  {t("about.description2")}
                 {""}   {""}
                  {t("about.description3")}
                </p>
              </div>
            </div>
          </div>

          <div className="about_section padding1">
            <div className="row">
              <div className="col-md-7 col-sm-6 col-xs-12">
                <div className="about_tx">
                  {/* <h3> {t("about.subtitle2")}</h3> */}
                  <p>{t("about.description5")}</p>
                </div>
              </div>

              <div className="col-md-5 col-sm-6 col-xs-12">
                <div className="about_pic">
                  <img className="" src={tm2} />
                </div>
              </div>
            </div>
          </div>
          <div class="trust_tx">
            <h3> {t("about.subtitle2")}</h3>
            <hr class="line3" />
          </div>
          <div className="about_section padding1">
            <div className="row">
              <div className="col-md-5 col-sm-6 col-xs-12">
                <div className="about_pic">
                  <img src={tm3} />
                </div>
              </div>

              <div className="col-md-7 col-sm-6 col-xs-12">
                <div className="about_tx">
                  <h3> {t("about.subtitle3")}</h3>

                  <p>{t("about.description6")}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="about_section padding1">
            <div className="row">
              <hr className="line4"></hr>

              <div className="col-md-5 dis_non">
                <div className="about_pic">
                  <img className="" src={tm4} />
                </div>
              </div>

              <div className="col-md-7 col-sm-6 col-xs-12">
                <div className="about_tx">
                  <h3> {t("about.subtitle5")}</h3>
                  <p>{t("about.description8")}</p>
                </div>
              </div>

              <div className="col-md-5 col-sm-6 col-xs-12 disply_non">
                <div className="about_pic">
                  <img className="" src={tm4} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
