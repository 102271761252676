import React from "react";
import picture from "../Image/picture.jpg";
import picture2 from "../Image/picture2.jpg";
import picture3 from "../Image/picture3.jpg";
import picture4 from "../Image/picture4.png";
import PaginationTop from "../../PaginationTop";
import { useTranslation } from "react-i18next";

const Information = () => {
  const { t } = useTranslation();
  return (
    <div>
      <PaginationTop />
      <div className="karauli_maharaj_mn">
        <div className="container">
          <h2>{t("homepage.maharaji_title")}</h2>
          <hr className="line"></hr>
          <p>
            {t("homepage.maharaji_subtitle")}
            <br />
          </p>
        </div>
      </div>

      <div className="about_mn">
        <div className="container">
          <div className="about_section">
            <div className="row">
              <div className="col-md-5 col-sm-6 col-xs-12">
                <div className="about_pic">
                  <img src={picture} />
                </div>
              </div>

              <div className="col-md-7 col-sm-6 col-xs-12">
                <div className="about_tx">
                  <p>
                    {t("homepage.about_maharaj1")}
                    {/* {t("homepage.about_maharaj0")} */}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="about_section padding1">
            <div className="row">
              <div className="col-md-7 col-sm-6 col-xs-12">
                <div className="about_tx">
                  <p>
                    {t("homepage.about_maharaj2")}
                    {t("homepage.about_maharaj3")}
                  </p>
                </div>
              </div>

              <div className="col-md-5 col-sm-6 col-xs-12">
                <div className="about_pic pic_wd">
                  <img className="" src={picture2} />
                </div>
              </div>
            </div>
          </div>
          <div className="about_section padding1">
            <div className="row">
              <div className="col-md-5 col-sm-6 col-xs-12">
                <div className="about_pic">
                  <img src={picture3} />
                </div>
              </div>
              <div className="col-md-7 col-sm-6 col-xs-12">
                <div className="about_tx">
                  <p>
                    {t("homepage.about_maharaj4.maharaj3_sec1")}
                    <br></br>

                    {t("homepage.about_maharaj4.maharaj3_sec2")}
                  </p>
                </div>
              </div>
              <p className="about_tx2P">{t("homepage.about_maharaj5")}</p>
            </div>
          </div>
          <div className="about_section padding1">
            <div className="row">
              <div className="col-md-7 col-sm-6 col-xs-12">
                <div className="about_tx">
                  <p>{t("homepage.about_maharaj6")}</p>
                </div>
              </div>
              <div className="col-md-5 col-sm-6 col-xs-12">
                <div className="about_pic">
                  <img className="" src={picture4} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
