import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import tm1 from "../Image/tm1.jpg";
import tm3 from "../Image/tm3.jpg";
import tm4 from "../Image/tm4.jpg";
import banner from "../Image/banner.jpg";
import { Link } from "react-router-dom";
import PaginationTop from "../../PaginationTop";
import { API_HEADER, GetPageData } from "../utiles/api_constant";

const HomePage = () => {
  const { t, i18n } = useTranslation();
  const api_url = GetPageData;
  const api_header = API_HEADER;
  const [marquee, setMarquee] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const url = api_url;
      const headers = api_header;
      const data = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        pageUniqueSerial: 0,
        pageCode: "BANNER", //"MISC",//UPDATES,BANNER
        calledBy: "ALL",
        pageDataHindi: "",
      };
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const responseData = await response.json();
          const respo = responseData?.data;
          setMarquee(respo?.pageDataList[0]);
        } else {
          throw new Error(
            `Request failed with status code: ${response.status}`()
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="">
      <PaginationTop />
      <div class="marquee">
        <marquee
          width="100%"
          direction="left"
          behavior="scroll"
          scrollamount="7"
        >
          {i18n.language === "hi" ? marquee.pageDataHindi : marquee?.pageData}
        </marquee>
      </div>
      <div className="banner_mn">
        <img className="" src={banner} />
      </div>
      <div className="ashrm_mn">
        <div className="container">
          <div className="box1">
            <h2> {t("homepage.title")}</h2>
            <hr className="line3"></hr>
            <p>{t("homepage.subtitle")} </p>

            
          </div>
        </div>
      </div>
      <div className="notes_mn">
        <div className="container">
          <p>
            <span>
              {t("logoContent.note")}:{""} -
            </span>{" "}
            {""}
            {""}
            {t("logoContent.note_desc")}
          </p>
        </div>
      </div>
      <div className="tample_mn2">
        <div className="tample_mn">
          <div className="container">
            <div className="tample_box">
              <div className="row">
                <h2> {t("about.subtitle2")}</h2>

                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="tample_size">
                    <img className="" src={tm1} />

                    <h4>{t("logoContent.tempdesc")}</h4>
                    <p>{t("logoContent.temp_desc2")}</p>

                    <Link
                      className="btn atf-themes-btn read2_btn_sld"
                      to="./about"
                    >
                      {" "}
                      {t("logoContent.read_more")}
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="tample_size">
                    <img className="" src={tm3} />

                    <h4>{t("logoContent.temp_title2")}</h4>
                    <p>{t("logoContent.temp_desc21")} </p>

                    <Link
                      className="btn atf-themes-btn read2_btn_sld"
                      to="./about"
                    >
                      {t("logoContent.read_more")}
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="tample_size">
                    <img className="" src={tm4} />

                    <h4>{t("logoContent.temp_title3")}</h4>
                    <p>{t("logoContent.temp_desc31")} </p>

                    <Link
                      className="btn atf-themes-btn read2_btn_sld"
                      to="./about"
                    >
                      {t("logoContent.read_more")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact_mn">
        <div className="container"></div>
      </div>
    </div>
  );
};

export default HomePage;
