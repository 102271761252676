import React, { useState, useEffect } from "react";
import { CCard, CCardHeader, CCol } from "@coreui/react";

import {
  API_HEADER,
  CommentList,
} from "../../../../../Components/utiles/api_constant";
import { DataGrid } from "@mui/x-data-grid";
import ReactLoading from "react-loading";

const Comment = ({ showSidebar }) => {
  const api_url = CommentList;
  const [isLoading, setIsLoading] = useState(true);
  const api_header = API_HEADER;
  const [commentList, setCommentList] = useState("");
  const columns = [
    {
      headerName: "Sr.",
      field: "commentUniqueSerial",
      width: 80,
      renderHeader: () => (
        <div style={{ fontWeight: "bold" }}>
          <span>Sr.</span>
        </div>
      ),
    },
    {
      headerName: "Name ",
      field: "name",
      width: 150,
      renderHeader: () => (
        <div style={{ fontWeight: "bold" }}>
          <span>Name</span>
        </div>
      ),
    },

    {
      headerName: "Phone",
      field: "mobile",
      width: 150,
      renderHeader: () => (
        <div style={{ fontWeight: "bold" }}>
          <span>Phone</span>
        </div>
      ),
    },

    {
      field: "email",
      headerName: "Email ID",
      width: 150,
      renderHeader: () => (
        <div style={{ fontWeight: "bold" }}>
          <span>Email ID</span>
        </div>
      ),
    },
    {
      headerName: "Comment",
      field: "comment",
      flex: 1,
      renderHeader: () => (
        <div style={{ fontWeight: "bold" }}>
          <span>Comment</span>
        </div>
      ),
    },

    {
      headerName: "Date/Time",
      field: "logCreateDatetime",
      width: 200,
      renderHeader: () => (
        <div style={{ fontWeight: "bold" }}>
          <span>Date/Time</span>
        </div>
      ),
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = api_url;
      const headers = api_header;
      const data = {
        brandCode: "SKDMT",
        countryCode: "IN",
        companyId: "SKDMT",
        userId: "VINOD",
        ipAddress: "1::1",
        pageUniqueSerial: 0,
        pageCode: "UPDATES", //"MISC",//UPDATES,BANNER
        calledBy: "ALL",
      };
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const responseData = await response.json();
          const respo = responseData?.data;
          setCommentList(respo?.commentList);
        } else {
          throw new Error(
            `Request failed with status code: ${response.status}`()
          );
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false); // Set loading to false after data is fetched
      }
    };
    fetchData();
  }, []);
  return (
    <div onClick={showSidebar}>
      <CCol xs={12}>
        <CCard className="mb-4" style={{ margin: "20px" }}>
          <CCardHeader>
            <div className="btn_tx">
              <>
                {" "}
                <p>Comment List</p>
              </>
            </div>
          </CCardHeader>
          <>
            <div style={{ height: 450, width: "100%" }}>
              {isLoading && (
                <div className="loading-container">
                  <ReactLoading
                    type="spin"
                    color="#9f0101 "
                    height={100}
                    width={100}
                  />
                </div>
              )}
              <DataGrid
                rows={commentList}
                rowSelection={false}
                getRowId={(r) => r.commentUniqueSerial}
                columns={columns}
                pageSize={true}
                pageSizeOptions={false}
              />
            </div>
          </>
        </CCard>
      </CCol>
    </div>
  );
};

export default Comment;
